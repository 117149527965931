import { useState } from "react"
import { ActionIcon, Loader, SegmentedControl } from "@mantine/core"
import { Trash } from "tabler-icons-react"

import { config } from "config"
import {
  DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ROLES,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useOpenRemoveModal } from "common/components/useOpenRemoveModal"
import {
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} from "wall/api/messages.endpoints"
import type { Message } from "wall/types/message.model"
import MessageImage from "./MessageImage"

function MessageRow({
  messageStart,
  message,
  onClick,
}: {
  messageStart: number
  message: Message
  onClick: VoidFunction
}) {
  const [updateMessage, { isLoading }] = useUpdateMessageMutation()

  const [approved, setApproved] = useState<boolean | undefined>(
    message.approved
  )

  const update = async (approved: boolean) => {
    setApproved(approved)
    await updateMessage({ ...message, approved })
  }

  const [deleteMsg] = useDeleteMessageMutation()

  const openRemoveModal = useOpenRemoveModal(
    `Supprimer le message ${messageStart} ?`,
    () => deleteMsg(message).then(() => onClick()),
    "."
  )

  return (
    <tr>
      <td>{messageStart}</td>
      <td>{message.author}</td>
      <td className="text-xs">
        {message.message.substring(0, config.walls.maxMessageSize)}
      </td>
      <td className="w-[10px]">
        {message.imageKey ? <MessageImage imageKey={message.imageKey} /> : ""}
      </td>
      <td className="text-left">
        {isLoading ? (
          <Loader />
        ) : (
          <SegmentedControl
            size="xs"
            value={approved ? "yes" : approved === false ? "no" : ""}
            data={[
              { label: "OUI", value: "yes" },
              { label: "NON", value: "no" },
            ]}
            styles={(theme) => ({
              labelActive: {
                "&[for$=-no]": {
                  color: theme.colors.red[8],
                },
                "&[for$=-yes]": {
                  color: theme.colors.green[8],
                },
              },
            })}
            onChange={(value) => update(value === "yes")}
          />
        )}
      </td>
      <td className="text-xs">
        <AccessRights
          authorizedOrganizations={
            DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ORGANIZATIONS
          }
          authorizedRoles={DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ROLES}
        >
          <ActionIcon onClick={openRemoveModal}>
            <Trash size={24} />
          </ActionIcon>
        </AccessRights>
      </td>
    </tr>
  )
}

export default MessageRow
