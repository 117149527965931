import { config } from "../../config";

export const getQuizPlayUrl = (gameId: string) =>
  `${config.webAppRoot}/quiz/${gameId}`;

export const getWallPlayUrl = (wallId: string) =>
  `${config.webAppRoot}/wall/${wallId}`;

export const getWallQrUrl = (wallId: string) =>
  `${config.webAppRoot}/screen/wall/${wallId}/showqr`;

export const getWallCastUrl = (wallId: string) =>
  `${config.webAppRoot}/screen/wall/${wallId}/cast`;

export const getWallCarrouselUrl = (wallId: string) =>
  `${config.webAppRoot}/screen/wall/${wallId}/carrousel`;
