import { motion } from "framer-motion"
import { useEffect, useState } from "react"

export function ParcDesPrincesScreenTimer({
  color,
  duration,
  initialDelay,
  resetTimer,
}: {
  color: string
  duration: number
  initialDelay: number
  resetTimer: boolean
}) {
  const [timeLeft, setTimeLeft] = useState(initialDelay + duration)

  useEffect(() => {
    if (resetTimer) {
      setTimeLeft(duration + initialDelay)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTimer])

  useEffect(() => {
    // Exit early if the time is already zero
    if (timeLeft === 0) return

    // Create a timer that counts down every second
    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // Clean up the timer when the component unmounts or the countdown finishes
    return () => clearInterval(timerId)
  }, [timeLeft])

  return (
    <motion.div
      variants={{
        initial: { scale: 0 },
        choices: { scale: 1 },
        solution: { scale: 0, marginLeft: "-6%", transition: { delay: 1 } },
      }}
    >
      <motion.svg
        viewBox="0 0 100 100"
        variants={{
          initial: { width: "0" },
          choices: { width: "300px" },
          solution: { width: 0 },
        }}
        style={{ height: "300px" }}
      >
        <motion.circle
          variants={{
            initial: { pathLength: 1 },
            choices: {
              pathLength: 0,
              transition: { duration, ease: "linear" },
            },
          }}
          transition={{ duration: 5 }}
          transform={"rotate(-90 50 50)"}
          cx={50}
          strokeWidth={5}
          stroke={color}
          fill={"none"}
          cy={50}
          r={45}
        />
        <motion.circle
          transform={"rotate(-90 50 50)"}
          cx={50}
          fill={"none"}
          cy={50}
          variants={{
            initial: {
              strokeWidth: 50,
              r: 25,
              stroke: `${color}`,
            },
            choices: {
              strokeWidth: 5,
              r: 45,
              stroke: `${color}`,
              transition: { delay: 1 },
            },
          }}
        />
        <motion.text
          x={50}
          y={55}
          fontSize={72}
          textAnchor={"middle"}
          dominantBaseline={"middle"}
          variants={{
            initial: { fill: "black" },
            choices: { fill: `${color}`, transition: { delay: 1 } },
          }}
        >
          {timeLeft}
        </motion.text>
      </motion.svg>
    </motion.div>
  )
}
