import { useCallback, useEffect, useMemo, useState } from "react"
import {
  ActionIcon,
  Button,
  Container,
  Divider,
  NumberInput,
  Text,
  Title,
} from "@mantine/core"
import { Refresh } from "tabler-icons-react"
import useWebSocket, { ReadyState } from "react-use-websocket"

import { config } from "config"
import { useDebug } from "common/debug/hooks/useDebug"
import { gameService } from "services/services"
import type { Game, GameState } from "quiz/types/game.model"
import type { Quiz } from "quiz/types/quiz.model"
import type { WebsocketMessage } from "common/models/WebsocketMessage.model"

interface QuizBlindtestAdminProps {
  game: Game
  quiz: Quiz
  refetch: () => void
}
export function QuizBlindtestAdmin({
  game,
  quiz,
  refetch,
}: QuizBlindtestAdminProps) {
  const { isDebugMode, log } = useDebug()
  const [gameState, setGameState] = useState<GameState>(game!.state)

  const [disableEnd, setDisableEnd] = useState(false)
  const [disableReset, setDisableReset] = useState(false)
  const [disableStart, setDisableStart] = useState(false)

  const [isQuizEnding, setIsQuizEnding] = useState(false)
  const [isQuizResetting, setIsQuizResetting] = useState(false)
  const [isQuizRunning, setIsQuizRunning] = useState(false)

  const [questionNumber, setQuestionNumber] = useState(1)

  const endQuiz = () => {
    setIsQuizEnding(true)
    setDisableEnd(true)
    gameService
      .end(game.id)
      .then(() => {
        setIsQuizEnding(false)
        setDisableEnd(false)
      })
      .then(refetch)
  }

  const resetQuiz = () => {
    setIsQuizResetting(true)
    setDisableReset(true)
    gameService
      .reset(game.id)
      .then(() => {
        setIsQuizResetting(false)
        setDisableReset(false)
      })
      .then(refetch)
    setQuestionNumber(1)
  }

  const startQuiz = useCallback(() => {
    setIsQuizRunning(true)
    setDisableStart(true)
    gameService.start({ gameId: game.id, questionNumber }).then(refetch)
  }, [game.id, questionNumber, refetch])

  const checkQuizStatus = () => {
    refetch()
  }

  useEffect(() => {
    if (gameState.step === "QUESTION" && gameState.questionStatus === "NEXT") {
      setDisableStart(false)
      setIsQuizRunning(false)
      if (
        gameState.questionIdx === questionNumber - 1 &&
        questionNumber < quiz.questions?.length
      ) {
        setQuestionNumber(questionNumber + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState])

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<WebsocketMessage>(config.wsApiRoot, {
      retryOnError: true,
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: 250,
    })

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        action: "register",
        payload: {
          appName: "screen",
          gameId: game.id,
        },
      })
    }
  }, [readyState, game, sendJsonMessage])

  useEffect(() => {
    if (lastJsonMessage?.type === "state") {
      setGameState(lastJsonMessage.payload)
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (isDebugMode && typeof gameState?.questionIdx === "number") {
      log(
        `questionNumber: ${questionNumber}; questionIdx: ${gameState.questionIdx}; Question Status: ${gameState.questionStatus}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebugMode, gameState])

  const questionNumberClass = useMemo(
    () =>
      `font-normal ${
        typeof game.state.questionIdx === "number" ? "" : "font-thin"
      }`,
    [game.state]
  )

  const questionStatusClass = useMemo(
    () =>
      `font-normal ${game.state.questionStatus ? "" : "font-thin"}`,
    [game.state]
  )

  return (
    <>
      <Button.Group className="flex content-center">
        <NumberInput
          className="w-20 mr-2"
          value={questionNumber}
          max={game.quizSnapshot.questions.length}
          min={1}
          onChange={(val) => {
            if (val && val <= quiz.questions?.length && val > 0) {
              setQuestionNumber(val)
            }
          }}
        />

        <Button
          className="bg-button-admin ml-2 mr-2 self-center"
          onClick={isQuizRunning ? checkQuizStatus : startQuiz}
          disabled={
            disableStart ||
            disableReset ||
            disableEnd ||
            gameState.step === "RESULTS" ||
            (gameState.questionStatus === "NEXT" &&
              typeof gameState.questionIdx === "number" &&
              quiz.questions &&
              quiz.questions.length > 0 &&
              quiz.questions.length - (gameState.questionIdx + 1) === 0)
          }
        >
          {isQuizRunning
            ? `Question en cours...`
            : `Démarrer Question ${questionNumber} `}
        </Button>

        <Button
          className="bg-button-play ml-5 mr-2 self-center"
          onClick={() => {
            if (!isQuizEnding) {
              endQuiz()
            }
          }}
          disabled={
            disableStart ||
            disableReset ||
            disableEnd ||
            gameState.step === "RESULTS" ||
            (typeof gameState.questionIdx === "number" &&
              gameState.questionIdx < quiz.questions?.length - 1)
          }
        >
          {isQuizEnding ? "Fin du Quiz en cours..." : "Terminer le Quiz"}
        </Button>
      </Button.Group>
      {isDebugMode ? (
        <>
          <Divider my="sm" />
          <Container className="flex content-center gap-x-5 mt-5 items-center">
            <Title order={4}> Game State</Title>
            <Text className="font-normal">
              step: <span className="ml-2 font-thin">{game.state.step}</span>
            </Text>
            <Text className={questionNumberClass}>
              question n°:
              <span className="ml-2 font-thin">
                {typeof game.state.questionIdx === "number"
                  ? game.state.questionIdx + 1
                  : ""}
              </span>
            </Text>
            <Text className={questionStatusClass}>
              status:
              <span className="ml-2 font-thin">{game.state.questionStatus}</span>
            </Text>
            <Button
              className="bg-warning ml-5 mr-2"
              onClick={() => {
                if (!isQuizResetting) {
                  resetQuiz()
                }
              }}
              disabled={disableStart || disableReset || disableEnd}
            >
              {isQuizResetting
                ? "Réinitialisation en cours..."
                : "Réinitialiser le Blind test"}
            </Button>
            <ActionIcon
              className="bg-warning text-white"
              variant={"default"}
              size={36}
              onClick={refetch}
            >
              <Refresh size={18} />
            </ActionIcon>
          </Container>
        </>
      ) : null}
    </>
  )
}
