import QRCode from "react-qr-code";
import { Group, Title } from "@mantine/core";
import { useParams } from "react-router-dom";
import { openWindow } from "../../common/utils/openWindow";
import { getWallPlayUrl } from "../../common/utils/routeHelpers";

function WallQRView() {
  const { wallId } = useParams<{ wallId: string }>();
  const url = getWallPlayUrl(wallId!);

  return (
    <Group>
      <div
        onClick={() => openWindow(url, 414, 816, "mobile")}
        className={"cursor-pointer"}
        style={{ background: "white", padding: "8px" }}
      >
        <QRCode value={url} size={200} />
      </div>
      <Title sx={{ fontFamily: "inherit" }}>
        Scannez le QR code pour envoyer vos messages !
      </Title>
    </Group>
  );
}

export default WallQRView;
