import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { ExternalLink } from "tabler-icons-react"

import { config } from "config"
import Collapsable from "common/admin/ui/Collapsable"
import { openQrCodeModal } from "common/admin/ui/openQrCodeModal"
import { useDebug } from "common/debug/hooks/useDebug"
import { getQuizPlayUrl } from "common/utils/routeHelpers"
import { openWindow } from "common/utils/openWindow"
import { gameService } from "services/services"
import { useGetGameQuery } from "quiz/api/games.endpoints"

import { QuizAdminDebug } from "quiz/admin/components/QuizAdminDebug"
import { QuizGamePlayersList } from "quiz/admin/components/QuizGamePlayersList"
import { QuizGameStateLabel } from "quiz/admin/components/QuizGameStateLabel"
import { QuizBreadcrumbs } from "quiz/admin/components/QuizBreadcrumbs"
import { QuizBlindtestAdmin } from "quiz/admin/components/QuizBlindtestAdmin"
import { quizMode, type Quiz } from "quiz/types/quiz.model"

interface QuizGameAdminViewProps {
  displayedHeight: number
  displayedWidth: number
  quiz: Quiz
}

export function QuizGameAdminView({
  displayedHeight,
  displayedWidth,
  quiz,
}: QuizGameAdminViewProps) {
  const { isDebugMode } = useDebug()
  const { gameId } = useParams()
  const {
    isFetching,
    isLoading,
    isSuccess,
    isUninitialized,
    refetch,
    data: game,
  } = useGetGameQuery(gameId!)

  const [starting, setStarting] = useState(false)

  const navigate = useNavigate()

  const runGame = useCallback(() => {
    setStarting(true)
    gameService.run(game!.id).then(refetch)
  }, [game, refetch])

  const deleteGame = useCallback(
    () => gameService.delete(game!.id).then(() => navigate(`./../..`)),
    [game, navigate]
  )

  useEffect(() => {
    if (game?.state?.step === "CREATED") {
      setStarting(false)
    }
  }, [game])

  return (
    <>
      {isFetching && isLoading && isUninitialized ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <LoadingOverlay visible={true} overlayOpacity={0} />
          </div>
        </div>
      ) : null}
      {isSuccess && game ? (
        <>
          <QuizBreadcrumbs quiz={quiz} game={game} />

          <Title order={3} align={"center"} m={"lg"}>
            {game?.name || `Partie #${game?.id?.split("-")[0]}`}
          </Title>

          <Stack>
            {isDebugMode && quiz.mode && quiz.mode !== quizMode.Blindtest ? (
              <QuizAdminDebug game={game!} refetch={refetch} />
            ) : null}
            <Collapsable title={"Infos"}>
              <Stack>
                <Group position={"apart"}>
                  <QuizGameStateLabel game={game!} />
                  {game?.state?.step === "CREATED" &&
                  quiz.mode &&
                  quiz.mode !== quizMode.Blindtest ? (
                    <Button
                      className="bg-button-admin"
                      onClick={runGame}
                      disabled={starting}
                    >
                      Lancer la partie
                    </Button>
                  ) : null}
                </Group>
                <TextInput
                  label={
                    <span className="flex">
                      URL de l'interface mobile (
                      <Text
                        component="span"
                        variant="link"
                        inherit
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          openQrCodeModal(getQuizPlayUrl(game!.id), game.name)
                        }
                      >
                        QR Code
                      </Text>
                      <Text component="span" mx={"xs"}>
                        |
                      </Text>
                      <Text
                        className="flex items-center"
                        component="span"
                        variant="link"
                        inherit
                        onClick={() =>
                          openWindow(
                            `/quiz/${game!.id}`,
                            undefined,
                            undefined,
                            "mobile"
                          )
                        }
                      >
                        <ExternalLink size={16} />
                        Formulaire d'inscription
                      </Text>
                      )
                    </span>
                  }
                  disabled={true}
                  value={`${config.webAppRoot}/quiz/${game!.id}`}
                />

                <TextInput
                  label={
                    <span className="flex">
                      URL de l'interface écran (
                      <Text
                        component="span"
                        variant="link"
                        inherit
                        sx={{ cursor: "pointer" }}
                      >
                        <Text
                          className="flex items-center"
                          onClick={() =>
                            openWindow(
                              `/screen/game/${game?.id}`,
                              displayedWidth,
                              displayedHeight
                            )
                          }
                          component="span"
                          inherit
                        >
                          <ExternalLink size={16} />
                          Ouvrir
                        </Text>
                      </Text>
                      )
                    </span>
                  }
                  disabled={true}
                  value={`${config.webAppRoot}/screen/game/${game!.id}`}
                />
              </Stack>
            </Collapsable>

            {quiz.mode && quiz.mode === quizMode.Blindtest ? (
              <Collapsable title="Blindtest">
                <QuizBlindtestAdmin
                  quiz={quiz}
                  game={game!}
                  refetch={refetch}
                />
              </Collapsable>
            ) : null}

            {game?.state?.step === "RESULTS" &&
              (() => {
                const leaderboard = game?.state?.leaderboard
                const winner = leaderboard?.[0]

                return (
                  <Collapsable title={"Gagnant"}>
                    <ol>
                      {(winner &&
                        leaderboard.slice(0, 3).map((player, index) => (
                          <li key={index}>
                            {player.firstName} {player.lastName} -{" "}
                            {player.phoneNumber} - tribune/accès:{" "}
                            {player.accessNumber}, rang: {player.rankNumber},
                            place: {player.seatNumber}
                            {game?.quizSnapshot?.mode === "price_is_right" && (
                              <>
                                , réponse:{" "}
                                {player.answers?.[0] === undefined
                                  ? "Aucune"
                                  : player.answers?.[0]}
                              </>
                            )}
                            <br />
                          </li>
                        ))) || <li>Personne</li>}
                    </ol>
                  </Collapsable>
                )
              })()}

            <Collapsable title="Joueurs">
              <QuizGamePlayersList />
            </Collapsable>

            <Group position={"center"} mt={"xl"}>
              <Button className="bg-warning text-white" onClick={deleteGame}>
                Supprimer
              </Button>
            </Group>
          </Stack>
        </>
      ) : null}
    </>
  )
}
