import { Route, Routes } from "react-router-dom"
import { PlayGamePresenter } from "quiz/play/views/PlayGamePresenter"
import { GamePlayThemeWrapper } from "quiz/layouts/GamePlayThemeWrapper"

export function QuizGamePlayRoutes() {
  return (
    <Routes>
      <Route path="" element={<GamePlayThemeWrapper />}>
        <Route path="" element={<PlayGamePresenter />}></Route>
      </Route>
      <Route path=":gameId" element={<GamePlayThemeWrapper />}>
        <Route path="" element={<PlayGamePresenter />}></Route>
      </Route>
    </Routes>
  )
}
