import { config } from "../config"
import { GameServiceMock } from "./game.service.mock"
import { GameService } from "./game.service"
import { EntityService } from "./entity.service"
import type { Message } from "../wall/types/message.model"
import type { Wall } from "../wall/types/wall.model"

export const gameService = config.mock
  ? new GameServiceMock()
  : new GameService()

export const wallService = new EntityService<Wall>("walls")

export const messageService = new EntityService<Message>("messages")
