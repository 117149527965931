import { Link } from "react-router-dom"

import { Anchor, Breadcrumbs } from "@mantine/core"

export function ClickAndCollectBreadcrumbs() {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>

      <span>Click & Collect</span>
    </Breadcrumbs>
  )
}
