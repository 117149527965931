import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import type { Quiz } from "quiz/types/quiz.model"

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Quiz", Quiz>("Quiz", "quizzes"),
})

export const {
  useGetQuizQuery,
  useListQuizzesQuery,
  useAddQuizMutation,
  useRemoveQuizMutation,
  useUpdateQuizMutation,
} = api
