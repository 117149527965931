import { useLayoutEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  Box,
  Divider,
  Input,
  Radio,
  SegmentedControl,
  Select,
  Stack,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core"
import { merge } from "lodash"

import {
  DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  ORGANIZATIONS,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import Collapsable from "common/admin/ui/Collapsable"
import { ColorsFormInput } from "common/admin/forms/ColorsFormInput"
import ImagesForm from "common/admin/forms/ImagesForm"
import QueryWrapper from "common/components/QueryWrapper"
import { MessagesList } from "wall/admin/wall/messages/MessagesList"
import { useGetWallQuery } from "wall/api/walls.endpoints"
import type { Wall } from "wall/types/wall.model"
import { wallTemplate } from "wall/types/wall.model"
import {
  defaultWall,
  useWallForm,
  WallFormProvider,
} from "wall/admin/wall/wallForm"
import { WallBreadcrumbs } from "wall/admin/components/WallBreadcrumbs"
import { WallToolbar } from "wall/admin/components/WallToolbar"

export default function WallView() {
  const { wallId } = useParams<{ wallId?: string }>()

  const form = useWallForm({
    initialValues: defaultWall,
  })
  const setFormValues = form.setValues

  const getWallQuery = useGetWallQuery(wallId ?? skipToken, {})

  useLayoutEffect(() => {
    getWallQuery.data &&
      setFormValues(merge({}, defaultWall, getWallQuery.data))
  }, [getWallQuery.data, setFormValues])

  const location = useLocation()

  useLayoutEffect(() => {
    const state = location.state as { from?: Wall } | undefined
    if (state?.from) {
      setFormValues(state.from)
      window.history.replaceState(null, "")
    }
  }, [location, setFormValues])

  return (
    <Box>
      <QueryWrapper query={getWallQuery}>
        {(wall) => (
          <WallFormProvider form={form}>
            <WallBreadcrumbs wall={wall} />

            <Title order={3} align={"center"} m={"lg"}>
              {wallId
                ? wall?.name || `Partie #${wall?.id?.split("-")[0]}`
                : "Nouveau diaporama"}
            </Title>

            <WallToolbar />

            <Stack>
              <Collapsable title="Paramètres">
                <Stack>
                  <AccessRights
                    authorizedOrganizations={
                      DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
                    }
                    authorizedRoles={
                      DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
                    }
                  >
                    <Select
                      sx={{ flex: 1 }}
                      label={"Organisme"}
                      data={ORGANIZATIONS}
                      {...form.getInputProps("organization")}
                    />
                  </AccessRights>
                  <Input.Wrapper label="Contenu du diaporama">
                    <div>
                      <SegmentedControl
                        className="w-full"
                        data={[
                          { value: "TEXT", label: "Texte" },
                          { value: "HYBRID", label: "Texte + image" },
                          { value: "IMAGES", label: "Image" },
                        ]}
                        {...form.getInputProps("type")}
                      />
                    </div>
                  </Input.Wrapper>
                  <TextInput
                    autoFocus={(location.state as any)?.from}
                    required
                    sx={{ flex: 1 }}
                    label={"Nom"}
                    {...form.getInputProps("name")}
                  />
                  <TextInput
                    required
                    sx={{ flex: 1 }}
                    label={"Libellé du titre du champ de saisie"}
                    placeholder="Message"
                    {...form.getInputProps("labelFormMessage")}
                  />
                  <TextInput
                    required
                    sx={{ flex: 1 }}
                    label={"Libellé du bouton de confirmation"}
                    placeholder="Envoyer un nouveau message"
                    {...form.getInputProps("sentButtonLabel")}
                  />
                  <Textarea
                    key="welcome-msg"
                    label="Message de confirmation d'envoi"
                    name="welcomeMsg"
                    placeholder="Votre message a bien été envoyé"
                    {...form.getInputProps("sentMessage")}
                  />
                </Stack>
              </Collapsable>

              <Collapsable title="Template">
                <Radio.Group
                  value={form.values.template}
                  onChange={(value) =>
                    form.setFieldValue("template", value as wallTemplate)
                  }
                >
                  {Object.entries(wallTemplate).map(([name, type], idx) => (
                    <Radio key={idx} value={type} label={name} />
                  ))}
                </Radio.Group>
              </Collapsable>

              <Collapsable title={"Accueil"}>
                <TextInput
                  required
                  sx={{ flex: 1 }}
                  label={"Titre de la page"}
                  placeholder="Inscription"
                  {...form.getInputProps("pageTitle")}
                />
                <Textarea
                  key="welcome-msg"
                  label="Message d'accueil"
                  name="welcomeMsg"
                  placeholder="Votre message d'accueil"
                  {...form.getInputProps("welcomeMsg")}
                />
              </Collapsable>

              {wallId && getWallQuery.currentData && (
                <Collapsable title={`Messages`}>
                  <MessagesList wallId={wallId} />
                </Collapsable>
              )}
              <Collapsable title={"Polices"}>
                <Select
                  classNames={{
                    root: "grid gap-2 grid-cols-4",
                    wrapper: "w-20",
                  }}
                  required
                  label={"Taille de police des messages"}
                  placeholder="6xl"
                  data={[
                    { value: "12px", label: "extra-small" },
                    { value: "14px", label: "small" },
                    { value: "16px", label: "normal" },
                    { value: "18px", label: "large" },
                    { value: "10px", label: "extra-large" },
                    { value: "24px", label: "2xl" },
                    { value: "30px", label: "3xl" },
                    { value: "36px", label: "4xl" },
                    { value: "48px", label: "5xl" },
                    { value: "60px", label: "6xl" },
                    { value: "72px", label: "7xl" },
                    { value: "96px", label: "8xl" },
                    { value: "128px", label: "9xl" },
                  ]}
                  {...form.getInputProps("msgFontSize")}
                />

                <Select
                  classNames={{
                    root: "grid gap-2 grid-cols-4",
                    wrapper: "w-20",
                  }}
                  required
                  label={"Taille de police des auteurs de message"}
                  placeholder="4xl"
                  data={[
                    { value: "12px", label: "extra-small" },
                    { value: "14px", label: "small" },
                    { value: "16px", label: "normal" },
                    { value: "18px", label: "large" },
                    { value: "10px", label: "extra-large" },
                    { value: "24px", label: "2xl" },
                    { value: "30px", label: "3xl" },
                    { value: "36px", label: "4xl" },
                    { value: "48px", label: "5xl" },
                    { value: "60px", label: "6xl" },
                    { value: "72px", label: "7xl" },
                    { value: "96px", label: "8xl" },
                    { value: "128px", label: "9xl" },
                  ]}
                  {...form.getInputProps("msgAuthorFontSize")}
                />
              </Collapsable>
              <Collapsable title={"Couleurs"}>
                <Box>
                  <Title order={4}>Formulaire d'inscription</Title>
                  <ColorsFormInput
                    form={form}
                    fields={[
                      //["background", "Fond de page"],
                      ["labels", "Libellés"],
                      ["info", "Message d'accueil"],
                      ["mandatory", "Astérisque"],
                      ["buttonBackground", "Fond du bouton"],
                      ["buttonBorder", "Bordure du bouton"],
                      ["buttonText", "Libellé de bouton"],
                      ["upload", "Libellé d'upload"],
                    ]}
                  />
                </Box>
                <br />
                <Divider my="sm" />
                <br />
                <Box>
                  <Title order={4}>Diaporama</Title>
                  <ColorsFormInput
                    form={form}
                    fields={[
                      ["messages", "Messages"],
                      ["authors", "Auteurs"],
                    ]}
                  />
                </Box>
              </Collapsable>

              <Collapsable title={"Images"}>
                <ImagesForm
                  form={form}
                  fields={[
                    ["screenBackground", "Arrière-plan (écran)"],
                    ["playBackground", "Arrière-plan (mobile)"],
                    ["confirmLogo", "Image de confirmation (mobile)"],
                    ["formLogo", "Image du formulaire spectateur (mobile)"],
                  ]}
                />
              </Collapsable>
            </Stack>
          </WallFormProvider>
        )}
      </QueryWrapper>
    </Box>
  )
}
