import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { config } from "config"
import { useGame } from "quiz/hooks/useGame"

import { quizMode, quizScreenType } from "quiz/types/quiz.model"
import type { GameState } from "quiz/types/game.model"
import type { WebsocketMessage } from "common/models/WebsocketMessage.model"

import { RunningQuizGame } from "./RunningQuizGame"
import { RunningBlindtestGame } from "./RunningBlindtestGame"

export function RunningGamePresenter() {
  const { gameId } = useParams()
  const game = useGame()
  const [gameState, setGameState] = useState<GameState>(game!.state)

  const quiz = game!.quizSnapshot
  const screenType: quizScreenType = quiz.screenType ?? "bandeau_horizontal"

  const backgroundSize = useMemo(
    () =>
      quiz?.screenType === "bandeau_horizontal"
        ? "1540px 220px"
        : "1920px 1080px",
    [quiz]
  )

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<WebsocketMessage>(config.wsApiRoot, {
      retryOnError: true,
      shouldReconnect: (closeEvent) => true,
    })

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        action: "register",
        payload: {
          appName: "screen",
          gameId,
        },
      })
    }
  }, [readyState, game, sendJsonMessage, gameId])

  useEffect(() => {
    if (lastJsonMessage?.type === "state") {
      setGameState(lastJsonMessage.payload)
    }
  }, [lastJsonMessage])

  if (game && game.quizSnapshot.mode === quizMode["Choix multiples"]) {
    return (
      <RunningQuizGame
        backgroundSize={backgroundSize}
        game={game}
        gameState={gameState}
        quiz={quiz}
        screenType={screenType}
      />
    )
  }

  if (game && game.quizSnapshot.mode === quizMode.Blindtest) {
    return (
      <RunningBlindtestGame
        backgroundSize={backgroundSize}
        game={game}
        gameState={gameState}
        quiz={quiz}
        screenType={screenType}
      />
    )
  }

  return null
}
