import { UseFormReturnType } from "@mantine/form";
import { Group, Stack } from "@mantine/core";
import FileInput from "common/admin/ui/FileInput";

function ImagesForm<T extends { images: Record<string, string> }>({
  form,
  fields,
}: {
  form: UseFormReturnType<T>;
  fields: [keyof T["images"], string][];
}) {
  return (
    <Stack>
      {fields.map(([name, label]) => (
        <Group key={name as string} grow>
          <FileInput
            label={label}
            {...form.getInputProps(`images.${String(name)}`)}
          />
        </Group>
      ))}
    </Stack>
  );
}

export default ImagesForm;
