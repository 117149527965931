import { Route, Routes } from "react-router-dom"
import { EventView } from "event/admin/views/EventView"
import { EventsListView } from "event/admin/views/EventsListView"

export function EventAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<EventsListView />} />
      <Route path="new" element={<EventView />} />
      <Route path=":eventId">
        <Route path="" element={<EventView />} />
      </Route>
    </Routes>
  )
}
