import { Box, Divider, Title } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { ColorsFormInput } from "common/admin/forms/ColorsFormInput"
import type { Quiz } from "quiz/types/quiz.model"

export function QuizColorsFormInput({
  form,
}: {
  form: UseFormReturnType<Omit<Quiz, "id">>
}) {
  return (
    <>
      <Box>
        <Title order={4}>Formulaire d'inscription</Title>
        <ColorsFormInput
          form={form}
          fields={[
            ["buttonBackground", "Fond du bouton"],
            ["buttonBorder", "Bordure du bouton actif"],
            ["buttonText", "Libellé de bouton actif"],
            ["labels", "Libellés"],
          ]}
        />
      </Box>
      <br />
      <Divider my="sm" />
      <Box>
        <Title order={4}>Quiz</Title>
        <ColorsFormInput
          form={form}
          fields={[
            ["background", "Fond du QR Code"],
            ["questionText", "Texte des questions"],
            ["choiceText", "Texte des réponses"],
            ["choiceBackground", "Fond des réponses"],
            ["choiceNumber", "Numéros des réponses"],
          ]}
        />
      </Box>
    </>
  )
}
