import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSessionStorage } from "@mantine/hooks";

export default function RequireAuth() {
  const [token] = useSessionStorage({
    key: "quizToken",
    getInitialValueInEffect: false,
  });
  const [role] = useSessionStorage({
    key: "adminRole",
    getInitialValueInEffect: false,
  });

  const location = useLocation();

  if (!token || !role) {
    return (
      <Navigate to="/admin/login" state={{ redirect: location.pathname }} />
    );
  } else {
    return <Outlet />;
  }
}
