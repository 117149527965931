import { Route, Routes } from "react-router-dom"
import { QuizScreenShellWithGameWrapper } from "quiz/layouts/QuizScreenShellWithGameWrapper"
import { RunningGamePresenter } from "quiz/screencast/views/RunningGamePresenter"

export function QuizScreencastRoutes() {
  return (
    <Routes>
      <Route path=":gameId" element={<QuizScreenShellWithGameWrapper />}>
        <Route path="" element={<RunningGamePresenter />} />
      </Route>
    </Routes>
  )
}
