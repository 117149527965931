import { useCallback, useEffect, useMemo, useState } from "react"
import { chunk, mapValues } from "lodash"
import {
  ActionIcon,
  Button,
  Group,
  LoadingOverlay,
  Pagination,
  Switch,
  Stack,
  Table,
} from "@mantine/core"
import { Refresh } from "tabler-icons-react"

import { getImageUrl } from "common/utils/getImageUrl"
import { useOrganization } from "common/admin/auth/hooks"
import QueryWrapper from "common/components/QueryWrapper"
import type { Message } from "wall/types/message.model"
import { useWall } from "wall/hooks/useWall"
import {
  useGetPresignedXMLConfigPostMutation,
  useUploadToS3Mutation,
} from "common/upload/api/upload.enpoints"
import {
  useListMessagesQuery,
  useUpdateMessageMutation,
} from "wall/api/messages.endpoints"
import MessageRow from "wall/admin/wall/messages/MessageRow"
import MessageStateSelect from "wall/admin/wall/messages/MessageStateSelect"
import { MessagesEditModal } from "wall/admin/wall/messages/MessagesEditModal"

export type MessageState = "all" | "rejected" | "approved" | "unset"

const MESSAGES_DISPLAYED = 20

type MessagesListProps = {
  wallId: string
}

const MessagesList = ({ wallId }: MessagesListProps) => {
  const [organization] = useOrganization()
  const wall = useWall()!
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const listMessagesQuery = useListMessagesQuery({ wallId: wall.id })
  const [stateFilter, setStateFilter] = useState<MessageState>("all")
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false)
  const [editedMessages, setEditedMessages] = useState<Message[]>([])
  const [uploadURL, setUploadURL] = useState<string | null>(null)
  const [resetSelection, setResetSelection] = useState(false)

  const [getPresignedPost, getPresignedPostResult] =
    useGetPresignedXMLConfigPostMutation()
  const [uploadToS3, uploadToS3Result] = useUploadToS3Mutation()

  const messagesByState = useMemo(() => {
    const messages = listMessagesQuery.data || []
    const msgApproved = messages.filter(({ approved }) => approved)
    const msgRejected = messages.filter(({ approved }) => approved === false)
    const msgUnset = messages.filter(({ approved }) => approved === undefined)
    return {
      all: [...messages],
      approved: msgApproved,
      rejected: msgRejected,
      unset: msgUnset,
    }
  }, [listMessagesQuery.data])

  const pages = useMemo(
    () => chunk(messagesByState[stateFilter], MESSAGES_DISPLAYED),
    [messagesByState, stateFilter]
  )

  const [pageIndex, setPageIndex] = useState(0)

  const organizationName = useMemo(
    () => (wall.organization ? wall.organization : organization),
    [organization, wall]
  )

  const handleApprovedMessagesLog = useCallback(() => {
    const approvedMessages = messagesByState.approved
    if (approvedMessages.length > 0) {
      setEditedMessages(approvedMessages)
      setIsEditingModalOpen(true)
    }
  }, [messagesByState])

  useEffect(() => {
    setPageIndex(0)
  }, [stateFilter])

  const submit = async () => {
    const xml: XMLDocument = document.implementation.createDocument(null, null)
    xml.append(
      xml.createProcessingInstruction("xml", 'version="1.0" encoding="UTF-8"')
    )
    const messages = xml.createElement("Questions")
    xml.append(messages)

    for (const message of editedMessages) {
      const messageElement = xml.createElement("Question")

      const nick = xml.createElement("Pseudo")
      nick.textContent = message.author
      messageElement.append(nick)

      const text = xml.createElement("Texte")
      text.textContent = message.message
      messageElement.append(text)

      if (
        message.imageKey &&
        (message.imageKeyApproved === true ||
          message.imageKeyApproved === undefined)
      ) {
        const pic = xml.createElement("image")
        pic.textContent = getImageUrl(message.imageKey)
        messageElement.append(pic)
      }

      messages.append(messageElement)
    }

    const stem =
      organizationName === "Nanterre"
        ? `MESSAGES-${organizationName.toUpperCase()}`
        : `MESSAGES-${wallId}`

    const filename = `${stem}.xml`
    const file = new File(
      [new XMLSerializer().serializeToString(xml)],
      filename
    )

    try {
      const data = await getPresignedPost({ filename: stem }).unwrap()
      await uploadToS3({ file, data })
    } catch (error) {
      console.log(error)
      return
    }
    setUploadURL(getImageUrl(filename))
  }

  const messages = useMemo(
    () => (listMessagesQuery.data ? listMessagesQuery.data : []),
    [listMessagesQuery.data]
  )

  useEffect(() => {
    if (needRefresh) {
      listMessagesQuery.refetch()
      setNeedRefresh(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh])

  const [updateMessage, { isLoading }] = useUpdateMessageMutation()

  useEffect(() => {
    if (!resetSelection) return
    if (resetSelection && messagesByState.approved.length > 0) {
      messagesByState.approved.map(updateApprovedMessage)
      setResetSelection(false)
    }

    async function updateApprovedMessage(msg: Message) {
      return await updateMessage({ ...msg, approved: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelection, messagesByState.approved])

  return (
    <>
      <QueryWrapper query={listMessagesQuery}>
        <Stack>
          <Group position={"apart"}>
            <Group spacing={5} position={"right"}>
              <MessageStateSelect
                value={stateFilter}
                onChange={setStateFilter}
                counts={mapValues(messagesByState, (m) => m.length)}
              />
              <ActionIcon
                variant={"default"}
                size={36}
                onClick={listMessagesQuery.refetch}
              >
                <Refresh size={18} />
              </ActionIcon>
            </Group>
            <Group spacing={4} position={"right"} className="flex">
              <span>Reset Sélection</span>
              <Switch
                checked={resetSelection}
                className="flex"
                onChange={(event) =>
                  setResetSelection(event.currentTarget.checked)
                }
              />
            </Group>
            <Pagination
              position={"right"}
              page={pageIndex + 1}
              onChange={(page) => setPageIndex(page - 1)}
              total={pages.length}
            />
          </Group>
          {messages.length > 0 && (
            <Stack>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Pseudo</th>
                    <th>Message</th>
                    <th>Image</th>
                    <th>Afficher</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <LoadingOverlay visible={isLoading} overlayBlur={2} />
                  {!isLoading
                    ? (pages[pageIndex] || []).map((message, indice) => {
                        const getMessageStart = () => {
                          const iterationIndice = indice + 1
                          return (
                            pageIndex * MESSAGES_DISPLAYED + iterationIndice
                          )
                        }
                        return (
                          <MessageRow
                            messageStart={getMessageStart()}
                            message={message}
                            key={message.id}
                            onClick={() => setNeedRefresh(true)}
                          />
                        )
                      })
                    : null}
                </tbody>
              </Table>
              <Pagination
                position={"right"}
                page={pageIndex + 1}
                onChange={(page) => setPageIndex(page - 1)}
                total={pages.length}
              />
              <Button
                className="bg-button-admin"
                onClick={handleApprovedMessagesLog}
                style={{ marginTop: "10px" }}
              >
                Prévisualiser la sélection
              </Button>
            </Stack>
          )}
        </Stack>
      </QueryWrapper>
      {isEditingModalOpen && (
        <MessagesEditModal
          uploadURL={uploadURL}
          setUploadURL={setUploadURL}
          isEditingModalOpen={isEditingModalOpen}
          setIsEditingModalOpen={setIsEditingModalOpen}
          getPresignedPostResult={getPresignedPostResult}
          uploadToS3Result={uploadToS3Result}
          editedMessages={editedMessages}
          setEditedMessages={setEditedMessages}
          onSubmit={submit}
        />
      )}
    </>
  )
}

export { MessagesList }
