import { Route, Routes } from "react-router-dom"
import WallsView from "wall/admin/walls/WallsView"
import WallView from "wall/admin/wall/WallView"

export function WallAdminRoutes() {
  return (
    <Routes>
      <Route element={<WallsView />} path="" />
      <Route element={<WallView />} path="new" />
      <Route element={<WallView />} path=":wallId" />
    </Routes>
  )
}
