import { GameService } from "services/game.service"
import type { Game } from "quiz/types/game.model"

export class GameServiceMock extends GameService {
  get(gameId: string) {
    return Promise.resolve({
      creationTime: "",
      entity: "Equipe 1ere homme",
      id: "52bd6d8e-63cd-41c4-8bfa-1bd5e4f81bd3",
      name: "Mapartie",
      organization: "PSG",
      quizId: "5458dead-5a81-4d00-b467-4b3a77a73ce8",
      quizSnapshot: {
        colors: {
          background: "#081E4F",
          buttonBackground: "#ffffff",
          buttonBorder: "#000000",
          buttonText: "#081E4F",
          choiceBackground: "#ffffff",
          choiceText: "#081E4F",
          choiceNumber: "#EE3224",
          labels: "#FFFFFF",
          questionText: "#ffffff",
        },
        creationTime: "",
        durations: { winner: 3, question: 5, choices: 10, solution: 5 },
        endingMobileMsg: "",
        endingMsg: "",
        entity: "Equipe 1ere homme",
        hasSeating: true,
        id: "5458dead-5a81-4d00-b467-4b3a77a73ce8",
        images: {
          gameOverLogo: "/psg/logo.png",
          playBackground: "/psg/bg.jpg",
          questionLogo: "/psg/logo.png",
          registerLogo: "/psg/logo.png",
          screenAnimatedLogo: "/psg/logo.png",
          screenBackground: "/psg/bg.jpg",
          screenBackgroundLeft: "/psg/bg_left.png",
          waitingLogo: "/psg/waiting_logo.png",
          winnerLogo: "/psg/logo.png",
        },
        name: "Azezaeaze",
        organization: "PSG",
        questions: [
          {
            choices: [
              { label: "28-28" },
              { label: "30-30" },
              { label: "32-32" },
            ],
            label:
              "Sur quel score s'est terminée la dernière rencontre des Parisiens à Coubertin ? ",
            solutionIdx: 0,
          },
          {
            choices: [
              { label: "Dunkerque" },
              { label: "Aix" },
              { label: "Montpellier" },
            ],
            label:
              "Face à quelle équipe le Paris Saint-Germain s'est-il imposé lors des quarts de finale de cette Coupe de France ?",
            solutionIdx: 0,
          },
          {
            choices: [{ label: "13" }, { label: "15" }, { label: "17" }],
            label:
              "Avec combien de buts d'écart les parisiens s'étaient ils imposés lors de cette rencontre ?",
            solutionIdx: 0,
          },
          {
            choices: [
              { label: "Kamil SYPRZAK" },
              { label: "Luka KARABATIC" },
              { label: "Nikola KARABATIC" },
            ],
            label:
              "Quel parisien avait inscrit le plus de buts au cours de cette rencontre ?",
            solutionIdx: 0,
          },
        ],
        section: "Football",
        screenType: "16_9",
        winnerMsg: "",
      },
      section: "Football",
      state: { step: "GAME_OVER" },
    } as Game)
  }
}
