import { apiSlice } from "core/api"

const api = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPresignedPost: build.mutation<
      { url: string; fields: Record<string, string> },
      { fileName: string; folder: string }
    >({
      query: (body) => ({
        method: "POST",
        url: "upload",
        body,
      }),
    }),

    getPresignedXMLConfigPost: build.mutation<
      { url: string; fields: Record<string, string> },
      { filename: string }
    >({
      query: (body) => ({
        method: "POST",
        url: "upload-xmlconfig",
        body,
      }),
    }),

    uploadToS3: build.mutation<
      void,
      { file: File; data: { url: string; fields: Record<string, string> } }
    >({
      query: ({ file, data: { url, fields } }) => {
        const body = new FormData()
        Object.entries(fields).forEach(([key, value]) =>
          body.append(key, value as string)
        )
        body.append("file", file)

        return {
          method: "POST",
          url,
          body,
          headers: { Authorization: "" },
        }
      },
    }),
  }),
})

export const {
  useGetPresignedPostMutation,
  useGetPresignedXMLConfigPostMutation,
  useUploadToS3Mutation,
} = api
