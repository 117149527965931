import { useMemo } from "react"
import { Menu } from "@mantine/core"
import { Download, Qrcode, ScreenShare } from "tabler-icons-react"
import { config, ScreenType } from "config"
import { useWall } from "wall/hooks/useWall"
import { useWallFormContext } from "../wall/wallForm"
import {
  useAddWallMutation,
  useRemoveWallMutation,
  useUpdateWallMutation,
} from "wall/api/walls.endpoints"
import { openWindow } from "common/utils/openWindow"
import {
  getWallCarrouselUrl,
  getWallCastUrl,
  getWallPlayUrl,
  getWallQrUrl,
} from "common/utils/routeHelpers"
import EntityToolbar from "common/admin/EntityToolbar"
import { openQrCodeModal } from "common/admin/ui/openQrCodeModal"

export function WallToolbar() {
  const wall = useWall()
  const form = useWallFormContext()

  const addWallMutation = useAddWallMutation()
  const updateWallMutation = useUpdateWallMutation()
  const removeWallMutation = useRemoveWallMutation()

  const screenType = useMemo<ScreenType | undefined>(
    () => (wall ? wall.template : undefined),
    [wall]
  )
  const displayedWidth = useMemo<number | undefined>(
    () =>
      screenType &&
      config.screenSizes[screenType] &&
      config.screenSizes[screenType].length > 0
        ? config.screenSizes[screenType][0]
        : undefined,
    [screenType]
  )
  const displayedHeight = useMemo<number | undefined>(
    () =>
      screenType &&
      config.screenSizes[screenType] &&
      config.screenSizes[screenType].length > 0
        ? config.screenSizes[screenType][1]
        : undefined,
    [screenType]
  )

  return (
    <EntityToolbar
      entity={wall}
      form={form}
      addMutation={addWallMutation}
      updateMutation={updateWallMutation}
      removeMutation={removeWallMutation}
      label="diaporama"
    >
      {wall && displayedWidth && displayedHeight && (
        <>
          <Menu.Item
            icon={<Download size={14} />}
            onClick={() => openQrCodeModal(getWallPlayUrl(wall.id), wall.name)}
          >
            Télécharger le QR Code
          </Menu.Item>

          <Menu.Item
            icon={<Qrcode size={14} />}
            onClick={() =>
              openWindow(getWallQrUrl(wall.id), displayedWidth, displayedHeight)
            }
          >
            Afficher le QR Code
          </Menu.Item>

          <Menu.Item
            icon={<Qrcode size={14} />}
            onClick={() => openWindow(`/wall/${wall.id}`)}
          >
            Afficher le formulaire d'accueil
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            icon={<ScreenShare size={14} />}
            onClick={() =>
              openWindow(
                getWallCastUrl(wall.id),
                displayedWidth,
                displayedHeight
              )
            }
          >
            Afficher le diaporama
          </Menu.Item>

          <Menu.Item
            icon={<ScreenShare size={14} />}
            onClick={() =>
              openWindow(
                getWallCarrouselUrl(wall.id),
                displayedWidth,
                displayedHeight
              )
            }
          >
            Afficher la frise
          </Menu.Item>
        </>
      )}
    </EntityToolbar>
  )
}
