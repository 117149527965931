import { useSessionStorage } from "@mantine/hooks"

export function useEntity() {
  return useSessionStorage<string | null>({ key: "entity" })
}

export function useOrganization() {
  return useSessionStorage<string | null>({ key: "organization" })
}

export function useQuizToken() {
  return useSessionStorage<string | null>({ key: "quizToken" })
}

export function useRole() {
  return useSessionStorage<string | null>({ key: "adminRole" })
}

export function useSection() {
  return useSessionStorage<string | null>({ key: "section" })
}
