import { Box, Image, Title } from "@mantine/core"
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from "react"

import { useDebug } from "common/debug/hooks/useDebug"
import type { GameState } from "quiz/types/game.model"
import type {
  MultipleChoiceQuestion,
  Question,
  Quiz,
} from "quiz/types/quiz.model"
import { ParcDesPrincesChoices } from "quiz/screencast/components/ParcDesPrincesChoices"
import { ParcDesPrincesScreenTimer } from "quiz/screencast/components/ParcDesPrincesScreenTimer"

const ParcDesPrincesQuestion = ({
  gameState,
  preview,
  question,
  quiz,
}: {
  gameState: GameState
  preview?: boolean
  question: Question
  quiz: Omit<Quiz, "id">
}) => {
  const { isDebugMode, log } = useDebug()
  const animation = useAnimation()
  const startAnimation = animation.start
  const { question: questionDuration, choices: choicesDuration } =
    quiz.durations

  const [start, setStart] = useState(false)
  const [resetTimer, setResetTimer] = useState(false)

  useEffect(() => {
    if (
      gameState.step === "QUESTION" &&
      gameState.questionStatus === "OPENED" &&
      typeof gameState.questionIdx === "number"
    ) {
      setStart(true)
      setResetTimer(true)
    }

    if (
      gameState.step === "QUESTION" &&
      gameState.questionStatus === "CLOSED"
    ) {
      setResetTimer(false)
    }
  }, [gameState])

  useEffect(() => {
    startAnimation("initial")
    if (start || preview) {
      startAnimation("logo")

      setTimeout(() => startAnimation("question"), 3000)
      setTimeout(() => startAnimation("choices"), questionDuration * 1000)
      setTimeout(
        () => startAnimation("solution"),
        (questionDuration + choicesDuration) * 1000
      )
      setStart(false)
    }
  }, [preview, start, startAnimation, questionDuration, choicesDuration])

  useEffect(() => {
    if (isDebugMode) {
      log(`questionDuration: ${questionDuration}`)
      log(`choicesDuration: ${choicesDuration}`)
      log(`question : ${JSON.stringify(question)}`)
      log(`gameState: ${JSON.stringify(gameState)}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebugMode])

  return (
    <Box
      sx={{
        color: quiz.colors.questionText,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        paddingRight: "10rem",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <motion.img
        src={quiz.images.screenAnimatedLogo}
        alt="Screen animated Logo"
        animate={animation}
        variants={{
          logo: {
            opacity: [0, 1, 1, 0],
            scale: [10, 1.05, 1, 0],
            transition: {
              duration: 3,
              times: [0, 0.2, 0.85, 1],
            },
          },
        }}
        style={{
          position: "absolute",
          left: "calc((100vw - 75vw) / 2)",
          maxWidth: "75vw",
        }}
      />
      <motion.div
        initial="initial"
        animate={animation}
        variants={{
          initial: { translateX: "-100vw" },
          choices: {
            translateX: "0vw",
            transition: { type: "spring", bounce: 0 },
          },
        }}
        style={{
          height: "100%",
          flexBasis: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            background: `url(${quiz.images.screenBackgroundLeft})`,
            backgroundPosition: "-30px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "65%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 0.5,
                marginTop: "4rem",
                paddingLeft: "4rem",
              }}
            >
              <Image src={quiz.images.questionLogo} alt="Question Logo" />
            </Box>
            <ParcDesPrincesScreenTimer
              color={quiz.colors.questionText}
              initialDelay={questionDuration}
              duration={choicesDuration}
              resetTimer={resetTimer}
            />
          </Box>
        </Box>
      </motion.div>
      <motion.div
        initial="initial"
        animate={animation}
        variants={{
          initial: {
            opacity: 0,
          },
          question: {
            translateY: "0vh",
            opacity: 1,
            transition: { type: "spring", bounce: 0 },
          },
        }}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexBasis: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <motion.div
            variants={{
              question: {
                opacity: [0, 1, 1, 1],
                scale: [10, 2.05, 2, 1],
                translateX: ["-20vw", "-20vw", "-20vw", "0vw"],
                translateY: [
                  "40vh",
                  "40vh",
                  "40vh",
                  question.type === "price_is_right" ? "40vh" : "0vh",
                ],
                transition: {
                  duration: quiz.durations.question - 3,
                  times: [0, 0.05, 0.95, 1],
                },
              },
              solution: {
                translateY: "0vh",
              },
            }}
            style={{
              marginBottom: "4rem",
              maxWidth: "45vw",
            }}
          >
            <Title
              order={1}
              align={"center"}
              sx={{
                fontFamily: "QuizCustomFont",
                fontSize: "80px",
                fontStyle: "oblique 5deg",
                fontWeight: "normal",
              }}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></Title>
          </motion.div>
        </Box>
        {[undefined, "multiple_choice", "blindtest"].includes(
          question.type
        ) && (
          <ParcDesPrincesChoices
            question={question as MultipleChoiceQuestion}
            quiz={quiz}
          />
        )}
        {question.type === "price_is_right" && (
          <motion.div
            style={{
              fontSize: "20rem",
              textAlign: "center",
              lineHeight: "80vh",
            }}
            variants={{
              initial: { translateY: "100vh", opacity: 0 },
              solution: {
                transition: { type: "spring", bounce: 0 },
                translateY: 0,
                opacity: 1,
              },
            }}
          >
            {question.solution}
          </motion.div>
        )}
      </motion.div>
    </Box>
  )
}

export { ParcDesPrincesQuestion }
