import type { Quiz, quizScreenType } from "quiz/types/quiz.model"

export const defaultQuizzes: Record<quizScreenType, Omit<Quiz, "id">> = {
  bandeau_horizontal: {
    colors: {
      background: "#02184D",
      buttonBackground: "#e30613",
      buttonBorder: "#ffffff",
      buttonText: "#ffffff",
      choiceBackground: "#ffffff",
      choiceText: "#004179",
      choiceNumber: "#EE3224",
      labels: "#FFFFFF",
      questionText: "#ffffff",
    },
    creationTime: "",
    durations: {
      question: 5,
      choices: 10,
      solution: 5,
      winner: 3,
    },
    endingMobileMsg: "",
    endingMsg: "",
    entity: "",
    hasSeating: true,
    images: {
      gameOverLogo: "/psg/example_player.png",
      playBackground: "/psg/bg.png",
      questionLogo: "/psg/logo.png",
      registerLogo: "/psg/logo.png",
      screenAnimatedLogo: "/psg/logo.png",
      screenBackground: "/psg/bg.png",
      screenBackgroundLeft: "/psg/bg_left.png",
      waitingLogo: "/psg/example_player.png",
      winnerLogo: "/psg/logo.png",
    },
    name: "",
    organization: "",
    questions: [],
    section: "",
    screenType: "bandeau_horizontal" as quizScreenType,
    waitingMsg: "",
    winnerMsg: "",
  },
  "16_9": {
    colors: {
      background: "#02184D",
      buttonBackground: "#e30613",
      buttonBorder: "#ffffff",
      buttonText: "#ffffff",
      choiceBackground: "#ffffff",
      choiceText: "#000000",
      choiceNumber: "#ffffff",
      labels: "#FFFFFF",
      questionText: "#ffffff",
    },
    creationTime: "",
    durations: {
      question: 5,
      choices: 10,
      solution: 5,
      winner: 3,
    },
    endingMobileMsg: "",
    endingMsg: "",
    entity: "",
    hasSeating: true,
    images: {
      gameOverLogo: "/psg/logo.png",
      playBackground: "/psg/bg.png",
      questionLogo: "/psg/logo.png",
      registerLogo: "/psg/logo.png",
      screenAnimatedLogo: "/psg/logo.png",
      screenBackground: "/psg/bg.png",
      screenBackgroundLeft: "/psg/bg_left.png",
      waitingLogo: "/psg/waiting_logo.png",
      winnerLogo: "/psg/psg-qatar.png",
    },
    name: "",
    organization: "",
    section: "",
    questions: [],
    screenType: "16_9" as quizScreenType,
    waitingMsg: "",
    winnerMsg: "",
  },
}
