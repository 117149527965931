import React from "react"
import { Route, Routes } from "react-router-dom"

import { PronosticsAdminView } from "pronostic/views/PronosticsAdminView"

export function PronosticsAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<PronosticsAdminView />} />
    </Routes>
  )
}
