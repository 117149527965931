import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import type { Game } from "quiz/types/game.model"

const apiCRUD = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Game", Game>("Game", "games"),
})

const api = apiCRUD.injectEndpoints({
  endpoints: (build) => ({
    getGamesByQuizId: build.query<any, { quizId: string }>({
      query: (arg) => {
        const { quizId } = arg
        return {
          url: "games",
          params: { quizId },
        }
      },
    }),
  }),
})

export const {
  useGetGameQuery,
  useListGamesQuery,
  useAddGameMutation,
  useRemoveGameMutation,
  useUpdateGameMutation,
  useGetGamesByQuizIdQuery,
} = api
