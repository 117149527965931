import { useMemo } from "react"
import { config, ScreenType } from "config"
import { useQuiz } from "quiz/hooks/useQuiz"
import type { Quiz } from "quiz/types/quiz.model"
import { QuizGameAdminView } from "./QuizGameAdminView"

export function QuizGameAdminPresenter() {
  const quiz: Quiz = useQuiz()!

  const screenType = useMemo<ScreenType | undefined>(
    () => (quiz ? quiz.screenType : undefined),
    [quiz]
  )

  const displayedWidth = useMemo<number | undefined>(
    () => (screenType ? config.screenSizes[screenType][0] : undefined),
    [screenType]
  )
  const displayedHeight = useMemo<number | undefined>(
    () => (screenType ? config.screenSizes[screenType][1] : undefined),
    [screenType]
  )
  return (
    <>
      {quiz && displayedWidth && displayedHeight ? (
        <QuizGameAdminView
          displayedHeight={displayedHeight}
          displayedWidth={displayedWidth}
          quiz={quiz}
        />
      ) : null}
    </>
  )
}
