import { Button, Group, Stack } from "@mantine/core"
import QRCode from "react-qr-code"

function QRCodeModal({
  url,
  fileName = "QRCode",
}: {
  url: string
  fileName?: string
}) {
  const downloadQR = () => {
    const svg = document.getElementById("qrcode")
    const svgData = svg!.outerHTML
    var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" })
    var svgUrl = URL.createObjectURL(svgBlob)
    var downloadLink = document.createElement("a")
    downloadLink.download = fileName + "_QR.svg"
    downloadLink.href = svgUrl
    downloadLink.click()
  }

  return (
    <Stack>
      <QRCode value={url} id="qrcode" />
      <Group position="right">
        <Button className="bg-button-admin" onClick={downloadQR}>
          Télécharger
        </Button>
      </Group>
    </Stack>
  )
}

export default QRCodeModal
