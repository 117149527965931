import { useContext, useState } from "react"
import { Helmet } from "react-helmet"
import { Container } from "@mantine/core"
import { useForm } from "@mantine/form"

import type { Message } from "wall/types/message.model"

import { useAddMessageMutation } from "wall/api/messages.endpoints"
import { PSGHeader } from "event/play/components/PSGHeader"
import { MessageSent } from "wall/play/components/MessageSent"
import { WallContext } from "wall/hooks/useWall"
import { PostMessageForm } from "wall/play/components/PostMessageForm"

export function PostMessageView() {
  const wall = useContext(WallContext)

  const form = useForm<Partial<Message>>({
    initialValues: {
      wallId: wall?.id,
      author: "",
      message: "",
    },
  })

  const [addMessage, addMessageResult] = useAddMessageMutation()
  const [isMessageSent, setIsMessageSent] = useState(false)

  return (
    <Container className="flex flex-col items-center min-h-screen">
      <Helmet>
        <title>{wall?.pageTitle}</title>
      </Helmet>
      <PSGHeader form={form} setIsMessageSent={setIsMessageSent} />

      {isMessageSent && addMessageResult.isSuccess ? (
        <MessageSent form={form} setIsMessageSent={setIsMessageSent} />
      ) : (
        <PostMessageForm
          addMessage={addMessage}
          addMessageResult={addMessageResult}
          form={form}
          setIsMessageSent={setIsMessageSent}
        />
      )}
    </Container>
  )
}
