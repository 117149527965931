import { UseFormReturnType } from "@mantine/form"
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Input,
  Radio,
  Stack,
  TextInput,
  Title,
} from "@mantine/core"
import { CirclePlus, Trash } from "tabler-icons-react"
import { useMemo } from "react"
import type { Quiz } from "quiz/types/quiz.model"

export function QuestionFormInput({
  form,
  questionIdx,
}: {
  form: UseFormReturnType<Omit<Quiz, "id">>
  questionIdx: number
}) {
  const question = useMemo(
    () => form.values.questions[questionIdx],
    [form.values, questionIdx]
  )

  return (
    <Stack>
      <TextInput
        autoFocus={question.label === ""}
        required
        sx={{ flex: 1 }}
        placeholder={"Intitulé de la question"}
        {...form.getInputProps(`questions.${questionIdx}.label`)}
      />
      {!question.type ||
      question.type === "multiple_choice" ||
      question.type === "blindtest" ? (
        <>
          <Title order={6}>Réponses</Title>
          {question.choices?.length > 0 && (
            <Radio.Group
              orientation={"vertical"}
              value={question.solutionIdx.toString()}
              onChange={(value) =>
                form.setFieldValue(
                  `questions.${questionIdx}.solutionIdx`,
                  parseInt(value)
                )
              }
            >
              {question.choices.map((solution, idx) => (
                <Radio
                  key={idx}
                  value={idx.toString()}
                  styles={{
                    body: {
                      alignItems: "center",
                      gap: "10px",
                    },
                    inner: {
                      alignSelf: "auto",
                    },
                    label: {
                      paddingLeft: 0,
                    },
                  }}
                  label={
                    <Group>
                      <Input
                        autoFocus={question.choices[idx].label === ""}
                        required
                        {...form.getInputProps(
                          `questions.${questionIdx}.choices.${idx}.label`
                        )}
                      />
                      <ActionIcon
                        color="red"
                        variant="subtle"
                        onClick={() => {
                          if (idx <= question.solutionIdx) {
                            form.setFieldValue(
                              `questions.${questionIdx}.solutionIdx`,
                              question.solutionIdx - 1
                            )
                          }
                          form.removeListItem(
                            `questions.${questionIdx}.choices`,
                            idx
                          )
                        }}
                      >
                        <Trash size={16} />
                      </ActionIcon>
                    </Group>
                  }
                />
              ))}
            </Radio.Group>
          )}
          <Box>
            <Button
              onClick={() =>
                form.insertListItem(`questions.${questionIdx}.choices`, {
                  label: "",
                })
              }
              variant={"subtle"}
              leftIcon={<CirclePlus />}
            >
              Ajouter une réponse
            </Button>
          </Box>
        </>
      ) : null}
      {question.type === "price_is_right" ? (
        <>
          <Title order={6}>Réponse</Title>
          <TextInput
            type="number"
            required
            sx={{ flex: 1 }}
            placeholder={"Réponse: un chiffre entier"}
            {...form.getInputProps(`questions.${questionIdx}.solution`)}
            onChange={(event) => {
              form.setFieldValue(
                `questions.${questionIdx}.solution`,
                Number(event.target.value) || null
              )
            }}
          />
        </>
      ) : null}
    </Stack>
  )
}
