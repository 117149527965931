import type { Game } from "quiz/types/game.model"
import { config } from "config"

export class GameService {
  private static headers() {
    return {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("quizToken")!
      )}`,
    }
  }

  create(game: Omit<Game, "id">): Promise<Game> {
    return fetch(`${config.apiRoot}/games`, {
      method: "POST",
      body: JSON.stringify(game),
      headers: GameService.headers(),
    }).then((resp) => resp.json())
  }

  delete(gameId: string): Promise<unknown> {
    return fetch(`${config.apiRoot}/games/${gameId}`, {
      method: "DELETE",
      headers: GameService.headers(),
    })
  }

  end(gameId: string): Promise<unknown> {
    return fetch(`${config.apiRoot}/games/end`, {
      method: "POST",
      body: JSON.stringify({ gameId }),
      headers: GameService.headers(),
    })
  }

  get(gameId: string): Promise<Game> {
    return fetch(`${config.apiRoot}/games/${gameId}`, {
      headers: GameService.headers(),
    }).then((resp) => resp.json())
  }

  getPlayers(gameId: string): Promise<any[]> {
    return fetch(`${config.apiRoot}/players?gameId=${gameId}`).then((resp) =>
      resp.json()
    )
  }

  reset(gameId: string): Promise<unknown> {
    return fetch(`${config.apiRoot}/games/reset`, {
      method: "POST",
      body: JSON.stringify({ gameId }),
      headers: GameService.headers(),
    })
  }

  run(gameId: string): Promise<unknown> {
    return fetch(`${config.apiRoot}/games/run`, {
      method: "POST",
      body: JSON.stringify({ gameId }),
      headers: GameService.headers(),
    })
  }

  start({
    gameId,
    questionNumber,
  }: {
    gameId: string
    questionNumber: number
  }): Promise<unknown> {
    return fetch(`${config.apiRoot}/games/start`, {
      method: "POST",
      body: JSON.stringify({ gameId, questionNumber }),
      headers: GameService.headers(),
    })
  }

  /** DEPRECATED */
  async upload(file: File): Promise<string> {
    const presignedPost = await (
      await fetch(`${config.apiRoot}/upload`, {
        method: "POST",
        body: JSON.stringify({ fileName: file.name, folder: "assets" }),
        headers: GameService.headers(),
      })
    ).json()

    const body = new FormData()
    Object.entries(presignedPost.fields).forEach(([key, value]) =>
      body.append(key, value as string)
    )
    body.append("file", file)

    await fetch(presignedPost.url, { method: "POST", body })

    const [, , endpoint, bucket] = presignedPost.url.split("/")
    return `https://${bucket}.${endpoint}/${presignedPost.fields.key}`
  }
}
