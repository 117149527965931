import { motion, useInView, useTime, useTransform } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import type { Message } from "wall/types/message.model"
import { getImageUrl } from "common/utils/getImageUrl"

const speeds = {
  0: (time) => 0,
  1: (time) => time / 50,
  2: (time) => time / 25,
  3: (time) => time / 10,
  4: (time) => time / 5,
  5: (time) => time / 2,
  6: (time) => time,
} as Record<number, (time: number) => number>

function ImagesSlideshow({ messages }: { messages: Message[] }) {
  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref)

  const images = messages
    .filter((message) => message.imageKey)
    .map((message) => getImageUrl(message.imageKey!))

  const extraImages = Array(10).fill(images).flat().slice(0, 10)

  const [speed, setSpeed] = useState(3)
  const [offset, setOffset] = useState<number>(0)

  const time = useTime()
  const x = useTransform(time, (time) => -speeds[speed](time) + offset)

  useEffect(() => {
    setOffset((offset) => offset - x.get())
  }, [isInView, x])

  useEffect(
    () =>
      document.addEventListener("keydown", (event) => {
        if (event.key === "ArrowRight") {
          setSpeed((speed) => {
            const s = Math.min(6, speed + 1)
            setOffset(
              (offset) =>
                offset + speeds[s](time.get()) - speeds[speed](time.get())
            )
            return s
          })
        } else if (event.key === "ArrowLeft") {
          setSpeed((speed) => {
            const s = Math.max(0, speed - 1)
            setOffset(
              (offset) =>
                offset + speeds[s](time.get()) - speeds[speed](time.get())
            )
            return s
          })
        }
      }),
    [messages, time]
  )

  return (
    <div className="w-screen overflow-hidden relative">
      <motion.div style={{ x }} className="flex no-wrap">
        <div className="flex no-wrap grow-0" ref={ref}>
          {images.map((src, idx) => (
            <img key={idx} src={src} className="h-screen" alt="" />
          ))}
        </div>
        <div className="flex no-wrap grow-0">
          {extraImages.map((src, idx) => (
            <img key={idx} src={src} className="h-screen" alt="" />
          ))}
        </div>
      </motion.div>
    </div>
  )
}

export default ImagesSlideshow
