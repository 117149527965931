import { Loader, Stack } from "@mantine/core"
import { PSGHeader } from "event/play/components/PSGHeader"

const PlayLoader = ({
  message,
  icon = <Loader variant={"bars"} color={"white"} />,
}: {
  message: JSX.Element | string
  icon?: JSX.Element
}) => {
  return (
    <Stack className="h-screen justify-start" justify="center" align={"center"}>
      <PSGHeader />
      {icon}
      {message}
    </Stack>
  )
}

export { PlayLoader }
