import { Alert, AlertProps, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";

function MutationWrapper({
  result,
  title,
  withDetails = false,
  message,
  ...props
}: {
  result: { isLoading: boolean; isError: boolean; data?: any; error?: any };
  title?: string;
  withDetails?: boolean;
  message?: string;
} & Partial<AlertProps>) {
  const [closed, setClosed] = useState(false);

  useEffect(() => setClosed(false), [result]);

  return (
    <>
      <LoadingOverlay visible={result.isLoading} overlayBlur={0} />
      {result.isError && !closed && (
        <Alert
          {...props}
          color="red"
          title={title ?? "Failed to save"}
          withCloseButton={true}
          onClose={() => setClosed(true)}
          classNames={{ title: withDetails ? "" : "mb-0" }}
        >
          {withDetails && (
            <div className="text-xs">
              {result.error?.data?.error?.exception?.description ||
                JSON.stringify(result.error?.data) ||
                JSON.stringify(result.error)}
            </div>
          )}
          {message}
        </Alert>
      )}
    </>
  );
}

export default MutationWrapper;
