import type { Event } from "event/types/event.model"

export function EventDate({
  event,
  className,
}: {
  event: Event
  className: string
}) {
  const localClassNames =
    "flex flex-col font-team-a-bold leading-4 ml-2 text-xs text-white capitalize"
  const classNames =
    className && className !== ""
      ? `${className} ${localClassNames}`
      : localClassNames
  return (
    <div className={classNames}>
      <span>
        {new Date(event.kickoff_date).toLocaleString(undefined, {
          weekday: "long",
        })}
      </span>
      <span>
        {new Date(event.kickoff_date).toLocaleString(undefined, {
          day: "numeric",
          month: "numeric",
          year: "2-digit",
        })}
      </span>
    </div>
  )
}
