import React, { useMemo } from "react"
import { useEntity, useOrganization, useRole, useSection } from "./hooks"

interface AccessRightsProps {
  children?: React.ReactNode
  authorizedEntities?: string[]
  authorizedRoles: string[]
  authorizedOrganizations?: string[]
  authorizedSections?: string[]
}

const AccessRights = ({
  children,
  authorizedEntities,
  authorizedRoles,
  authorizedOrganizations,
  authorizedSections,
}: AccessRightsProps) => {
  const [entity] = useEntity()
  const [role] = useRole()
  const [organization] = useOrganization()
  const [section] = useSection()

  const isEntityAllowed = useMemo<boolean>(
    () =>
      entity && authorizedEntities
        ? authorizedEntities.includes(entity)
        : false,
    [authorizedEntities, entity]
  )

  const isRoleAllowed = useMemo<boolean>(
    () => (role ? authorizedRoles.includes(role) : false),
    [authorizedRoles, role]
  )
  const isOrganizationAllowed = useMemo<boolean>(
    () =>
      organization && authorizedOrganizations
        ? authorizedOrganizations.includes(organization)
        : false,
    [authorizedOrganizations, organization]
  )

  const isSectionAllowed = useMemo<boolean>(
    () =>
      section && authorizedSections
        ? authorizedSections.includes(section)
        : false,
    [authorizedSections, section]
  )

  return (
    <>
      {isEntityAllowed &&
      isRoleAllowed &&
      isOrganizationAllowed &&
      isSectionAllowed ? (
        <>{children}</>
      ) : null}
      {!authorizedEntities &&
      isRoleAllowed &&
      isOrganizationAllowed &&
      isSectionAllowed ? (
        <>{children}</>
      ) : null}
      {!authorizedEntities &&
      isRoleAllowed &&
      isOrganizationAllowed &&
      !authorizedSections ? (
        <>{children}</>
      ) : null}
      {!authorizedEntities &&
      isRoleAllowed &&
      !authorizedOrganizations &&
      !authorizedSections ? (
        <>{children}</>
      ) : null}
    </>
  )
}

export { AccessRights }
