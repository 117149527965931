import { Switch } from "@mantine/core"
import { DEBUG_MODE } from "config"
import { useDebug } from "../hooks/useDebug"

interface WithDebugProps {}

interface Props extends WithDebugProps {
  children?: React.ReactNode
}

export function withDebug<T extends WithDebugProps = Props>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component"

  function ComponentWithDebug(props: Omit<T, keyof WithDebugProps>) {
    const { isDebugMode, toggleDebugMode } = useDebug()

    return (
      <>
        <WrappedComponent {...(props as T)} />
        {DEBUG_MODE ? (
          <Switch
            className="self-center"
            checked={isDebugMode}
            label="Debug Mode"
            onLabel="ON"
            offLabel="OFF"
            onChange={() => {
              toggleDebugMode()
            }}
          />
        ) : null}
      </>
    )
  }
  ComponentWithDebug.displayName = `withDebug(${displayName})`

  return ComponentWithDebug
}
