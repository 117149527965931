import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import type { Event } from "event/types/event.model"

const api = apiSlice
  .injectEndpoints({
    endpoints: makeCrudEndpoints<"Event", Event>("Event", "events"),
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listPogs: build.query({
        query: (eventId: string) => ({
          method: "GET",
          url: `pog/${eventId}`,
        }),
      }),
      votePog: build.mutation({
        query: ({
          eventId,
          playerId,
        }: {
          eventId: string
          playerId: string
        }) => ({
          method: "POST",
          url: `pog/${eventId}`,
          body: { playerId },
        }),
      }),
    }),
  })

export const {
  useGetEventQuery,
  useListEventsQuery,
  useAddEventMutation,
  useRemoveEventMutation,
  useUpdateEventMutation,
  useListPogsQuery,
  useVotePogMutation,
} = api
