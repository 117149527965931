import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { ActionIcon, Loader, Popover, TextInput } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { Upload } from "tabler-icons-react"

import {
  useGetPresignedPostMutation,
  useUploadToS3Mutation,
} from "common/upload/api/upload.enpoints"
interface FileInputProps {
  className?: string
  isPDF?: boolean
  label: string
  onChange: (event: ChangeEvent<HTMLInputElement> | string) => void
  value: string
}

export default function FileInput({
  className,
  isPDF,
  label,
  onChange,
  value,
}: FileInputProps) {
  const [getPresignedPost, getPresignedPostResult] =
    useGetPresignedPostMutation()
  const [uploadToS3, uploadToS3Result] = useUploadToS3Mutation()

  const {
    isError: isErrorGetPresignedPostResult,
    error: errorGetPresignedPostResult,
  } = getPresignedPostResult

  const { isError: isErrorUploadToS3Result, error: errorUploadToS3Result } =
    uploadToS3Result

  const textInput = useRef<HTMLInputElement>(null)
  const fileInput = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = useState<boolean>(false)

  const [opened, { close, open }] = useDisclosure(false)

  const upload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        setUploading(true)
        const presignedPost = await getPresignedPost({
          fileName: file.name,
          folder: "assets",
        }).unwrap()
        const [, , endpoint, bucket] = presignedPost.url.split("/")

        await uploadToS3({ file, data: presignedPost })
        setUploading(false)
        onChange(`https://${bucket}.${endpoint}/${presignedPost.fields.key}`)
      }
    },
    [getPresignedPost, onChange, uploadToS3]
  )

  useEffect(() => {
    if (isErrorGetPresignedPostResult) {
      console.error(
        "PresignedPostError",
        JSON.stringify(errorGetPresignedPostResult)
      )
    }
    if (isErrorUploadToS3Result) {
      console.error("UploadToS3Error", JSON.stringify(errorUploadToS3Result))
    }
  }, [
    isErrorGetPresignedPostResult,
    isErrorUploadToS3Result,
    errorGetPresignedPostResult,
    errorUploadToS3Result,
  ])

  return (
    <>
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
        withinPortal
      >
        <Popover.Target>
          <TextInput
            required
            ref={textInput}
            value={value}
            onChange={onChange}
            className={className}
            label={label}
            onMouseEnter={open}
            onMouseLeave={close}
            rightSection={
              <ActionIcon
                variant={"transparent"}
                onClick={() => fileInput.current?.click()}
              >
                {uploading ? <Loader size={20} /> : <Upload size={20} />}
              </ActionIcon>
            }
          />
        </Popover.Target>
        {value !== "" && !isPDF ? (
          <Popover.Dropdown
            sx={{
              pointerEvents: "none",
              maxWidth: "35vw",
              maxHeight: "35vh",
              width: "auto",
              height: "auto",
            }}
          >
            <img
              src={value}
              alt={"Non trouvée"} // Not for accessibility, rather text fallback for browsers
              style={{
                maxWidth: "calc(35vw - 32px)",
                maxHeight: "calc(35vh - 32px)",
              }}
            />
          </Popover.Dropdown>
        ) : null}
      </Popover>

      <input
        accept={isPDF ? "application/pdf" : "image/png, image/jpeg"}
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        onChange={upload}
      />
    </>
  )
}
