import { useMemo, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/Page/TextLayer.css"
import "./PDFViewer.css"

// Configure the pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface PDFViewerProps {
  pdfFileUrl: string
}

export function PDFViewer({ pdfFileUrl }: PDFViewerProps) {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const file = useMemo(() => {
    return { url: pdfFileUrl }
  }, [pdfFileUrl])

  return (
    <div className="pdf-container">
      <Document
        file={file} // You can use a local file or URL
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="pdf-controls">
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages}
        </p>
        {pageNumber > 1 ? (
          <button disabled={pageNumber <= 1} onClick={previousPage}>
            Previous
          </button>
        ) : null}
        {numPages && numPages > 1 && numPages !== pageNumber ? (
          <button
            disabled={numPages ? pageNumber >= numPages : true}
            onClick={nextPage}
          >
            Next
          </button>
        ) : null}
      </div>
    </div>
  )
}
