import {
  Select,
  SelectProps,
} from "@mantine/core";

import { Player } from "../../../workforce/admin/workforce.model";

interface PlayerSelectProps extends Partial<SelectProps> {
  players?: Player[];
}

export default function PlayerSelect({ players, ...others }: PlayerSelectProps) {
  return (
    <Select
      data={players?.map(
        (player, index) => ({ label: player.name, value: index.toString() })
      ) || []}
      {...others}
    />
  );
}
