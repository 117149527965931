export function AdminStagingHome() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Staging environment</h1>
      <button
        style={{
          backgroundColor: "teal",
          border: "thick double cyan",
          color: "white",
          padding: "10px 10px",
        }}
        type="button"
      >
        <a href="/admin/login">Go to admin</a>
      </button>
    </div>
  )
}
