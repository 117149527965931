import { useMemo, useState } from "react"
import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Text,
  Title,
} from "@mantine/core"
import Collapsable from "common/admin/ui/Collapsable"
import { gameService } from "services/services"
import { Game } from "quiz/types/game.model"
import { Refresh } from "tabler-icons-react"

export function QuizAdminDebug({
  game,
  refetch,
}: {
  game: Game
  refetch: () => void
}) {
  const [isQuizResetting, setIsQuizResetting] = useState(false)
  const [disableReset, setDisableReset] = useState(false)

  const resetQuiz = () => {
    setIsQuizResetting(true)
    setDisableReset(true)
    gameService
      .reset(game.id)
      .then(() => {
        setIsQuizResetting(false)
        setDisableReset(false)
      })
      .then(refetch)
  }

  const questionNumberClass = useMemo(
    () =>
      `font-normal ${
        typeof game.state.questionIdx === "number" ? "" : "font-thin"
      }`,
    [game.state]
  )

  const questionStatusClass = useMemo(
    () => `font-normal ${game.state.questionStatus ? "" : "font-thin"}`,
    [game.state]
  )

  return (
    <Collapsable title={"Debug"}>
      <Container className="flex content-center gap-x-5 items-center">
        <Text className="font-normal">
          Creation Date:{" "}
          <span className="font-thin">
            {new Intl.DateTimeFormat("fr-FR", {
              dateStyle: "short",
              timeStyle: "short",
            }).format(new Date(game.creationTime))}
          </span>
        </Text>
        <Text className="font-normal">
          entity: <span className="font-thin">{game.entity}</span>
        </Text>
        <Text className="font-normal">
          section: <span className="font-thin">{game.section}</span>
        </Text>
        <Text className="font-normal">
          organization: <span className="font-thin">{game.organization}</span>
        </Text>
        <Button
          className="bg-warning mr-2"
          onClick={() => {
            if (!isQuizResetting) {
              resetQuiz()
            }
          }}
          disabled={disableReset}
        >
          {isQuizResetting
            ? "Réinitialisation en cours..."
            : "Réinitialiser le  Quiz"}
        </Button>
      </Container>
      <Divider my="sm" />
      <Container className="flex content-center gap-x-10 mt-5 items-center">
        <Title order={4}> Game State</Title>
        <Text className="font-normal">
          step: <span className="font-thin">{game.state.step}</span>
        </Text>
        <Text className={questionNumberClass}>
          question n°:
          <span className="font-thin">
            {typeof game.state.questionIdx === "number"
              ? game.state.questionIdx + 1
              : ""}
          </span>
        </Text>
        <Text className={questionStatusClass}>
          status: <span className="font-thin">{game.state.questionStatus}</span>
        </Text>
        <ActionIcon
          className="bg-warning text-white"
          variant={"default"}
          size={36}
          onClick={refetch}
        >
          <Refresh size={18} />
        </ActionIcon>
      </Container>
    </Collapsable>
  )
}
