import { useEffect, useMemo, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import {
  Container,
  Center,
  Group,
  Image,
  Loader,
  Stack,
  Text,
} from "@mantine/core"
import { Check, X } from "tabler-icons-react"

import { BLINDTEST_WAITING_MSG, BLINDTEST_WAITING_RESULT_MSG } from "_constants"
import type { Nullable } from "types"
import type { Question, QuestionStatus, Quiz } from "quiz/types/quiz.model"

import { CoubertinTimer } from "quiz/play/components/CoubertinTimer"
import { ParcDesPrincesTimer } from "quiz/play/components/ParcDesPrincesTimer"
import { PSGHeader } from "event/play/components/PSGHeader"
import { GameState } from "quiz/types/game.model"

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

export function BlindtestChoices({
  gameState,
  question,
  questionStatus,
  quiz,
  answerIdx,
  solutionIdx,
  answer,
}: {
  gameState: GameState
  question: Question
  questionStatus: QuestionStatus
  quiz: Quiz
  answerIdx: Nullable<number>
  solutionIdx: Nullable<number>
  answer: (answerIdx: number) => void
}) {
  const [showTimer, setShowTimer] = useState(false)
  const [localAnswerIdx, setLocalAnswerIdx] = useState<Nullable<number>>()
  const [hasAnswerBeenClicked, setHasAnswerBeenClicked] = useState(false)
  const totalQuestions = useMemo(() => quiz.questions.length, [quiz])

  useEffect(() => {
    if (answerIdx === null && localAnswerIdx !== null) {
      setLocalAnswerIdx(null)
    }
    if (solutionIdx !== null && hasAnswerBeenClicked) {
      setHasAnswerBeenClicked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerIdx, solutionIdx])

  const { question: questionDuration } = quiz.durations

  const animation = useAnimation()
  const startAnimation = animation.start
  const stopAnimation = animation.stop

  useEffect(() => {
    if (questionStatus === "OPENED") {
      startAnimation("initial")
      setTimeout(
        () => startAnimation("choices"),
        (questionDuration + 0.1) * 1000
      )
    }
    if (questionStatus === "STARTED") {
      startAnimation("choices")
      setShowTimer(true)
    }
    if (questionStatus === "CLOSED") {
      // startAnimation("choices")
      setShowTimer(false)
    }
    if (questionStatus === "NEXT") {
      stopAnimation()
      setShowTimer(false)
    }
  }, [
    question,
    questionDuration,
    questionStatus,
    startAnimation,
    stopAnimation,
  ])

  const screenType = quiz.screenType ?? "bandeau_horizontal"
  const Timer =
    screenType === "bandeau_horizontal" ? CoubertinTimer : ParcDesPrincesTimer

  const headTitle = useMemo(() => {
    if (
      questionStatus === "NEXT" &&
      typeof gameState.questionIdx === "number" &&
      gameState.questionIdx === totalQuestions - 1
    ) {
      return BLINDTEST_WAITING_RESULT_MSG
    }
    if (
      questionStatus === "NEXT" &&
      typeof gameState.questionIdx === "number" &&
      gameState.questionIdx < totalQuestions
    ) {
      return BLINDTEST_WAITING_MSG
    }

    return question.label
  }, [gameState, question, questionStatus, totalQuestions])

  return (
    <Container
      sx={{
        minHeight: "100dvh",
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <PSGHeader />
      <Center>
        <Image
          src={quiz.images.questionLogo}
          width={75}
          style={{ paddingBottom: "10px" }}
          alt="Question Logo"
        />
      </Center>

      <motion.div
        initial="initial"
        animate={{ right: "0vw" }}
        variants={{
          initial: { position: "relative", right: "100vw" },
        }}
        transition={{ type: "spring", bounce: 0 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: "0 auto",
            paddingBottom: "2vh",
          }}
        >
          <h2
            style={{
              fontSize: "1.2rem",
              fontFamily: "Team-A-Bold",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{ __html: headTitle }}
          ></h2>
        </div>
      </motion.div>
      {questionStatus !== "NEXT" ? (
        <motion.div
          initial="initial"
          animate={animation}
          variants={{
            initial: { opacity: 0 },
            choices: {
              opacity: 1,
              transition: { staggerChildren: 0.1 },
            },
            transitionEnd: { display: "none" },
          }}
        >
          <Stack
            style={{
              width: "350px",
            }}
          >
            {question.choices?.map((choice, choiceIndex) => (
              <motion.div
                key={choiceIndex}
                variants={{
                  hidden: { position: "relative", right: "100vw" },
                  show: {
                    right: "0vw",
                    transition: { type: "spring", bounce: 0 },
                  },
                }}
              >
                <Group
                  sx={{
                    background: quiz.colors.choiceBackground,
                    borderRadius: 25,
                    gridColumn: "span 3 / span 3",
                    justifyContent: "space-between",
                    paddingRight: "5vw",
                    paddingLeft: "5vw",
                    opacity:
                      localAnswerIdx !== null && localAnswerIdx !== choiceIndex
                        ? 0.5
                        : 1,
                  }}
                  key={choice.label}
                  p={"sm"}
                  onClick={() => {
                    if (
                      hasAnswerBeenClicked ||
                      answerIdx != null ||
                      localAnswerIdx != null ||
                      solutionIdx != null ||
                      !showTimer
                    ) {
                      return
                    }
                    setHasAnswerBeenClicked(true)
                    setLocalAnswerIdx(choiceIndex)
                    answer(choiceIndex)
                  }}
                >
                  <div className="flex">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        color: "red",
                        width: "1.5em",
                        height: "1.5em",
                        fontFamily: "Team-A-Bold",
                      }}
                    >
                      {letters[choiceIndex]}.
                    </div>
                    <Text
                      sx={{
                        color: quiz.colors.choiceText,
                        flexGrow: 1,
                        fontFamily: "Team-A-Bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {choice.label}
                    </Text>
                  </div>
                  {hasAnswerBeenClicked &&
                  localAnswerIdx !== null &&
                  localAnswerIdx === choiceIndex &&
                  solutionIdx === null &&
                  answerIdx === null ? (
                    <Loader size={30} sx={{ color: quiz.colors.choiceText }} />
                  ) : null}
                  {solutionIdx != null && solutionIdx === choiceIndex ? (
                    <Check
                      size={30}
                      strokeWidth={3}
                      style={{
                        color: quiz.colors.choiceText,
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                  ) : null}
                  {solutionIdx != null &&
                  solutionIdx !== choiceIndex &&
                  choiceIndex === answerIdx &&
                  questionStatus === "ANSWERED" ? (
                    <X
                      size={30}
                      strokeWidth={3}
                      style={{
                        color: quiz.colors.choiceText,
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                  ) : null}
                </Group>
              </motion.div>
            ))}
          </Stack>

          <motion.div
            initial="choices"
            animate={animation}
            style={{
              scale: 0.5,
              display: "flex",
              justifyContent: "center",
              minHeight: "10dvh",
            }}
          >
            {showTimer ? (
              <Timer
                color={quiz.colors.questionText}
                initialDelay={0.5}
                duration={quiz.durations.choices}
              />
            ) : null}
          </motion.div>
        </motion.div>
      ) : null}
    </Container>
  )
}
