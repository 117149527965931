import { useLocation, useParams } from "react-router-dom"

import type { Event } from "event/types/event.model"
import { useGetEventQuery } from "event/api/event.api"

export function useEvent() {
  const location = useLocation()
  const state = location.state as { event?: Event } | undefined
  const params = useParams<{ eventId: string }>()
  const eventId = state?.event ? state.event.id : params.eventId!
  return useGetEventQuery(eventId!).data
}
