import { useWall, WallContext } from "wall/hooks/useWall"
import { PostMessageView } from "wall/play/views/PostMessageView"

export function PostMessagePresenter() {
  const wall = useWall()

  return (
    <>
      {wall ? (
        <WallContext.Provider value={wall}>
          <PostMessageView />
        </WallContext.Provider>
      ) : null}
    </>
  )
}
