import { config } from "../config";

export class EntityService<T> {
  constructor(private readonly path: string) {}

  get(id: string): Promise<T> {
    return fetch(`${config.apiRoot}/${this.path}/${id}`).then((resp) =>
      resp.json()
    );
  }

  list(): Promise<T[]> {
    return fetch(`${config.apiRoot}/${this.path}`).then((resp) => resp.json());
  }

  delete(id: string): Promise<unknown> {
    return fetch(`${config.apiRoot}/${this.path}/${id}`, {
      method: "DELETE",
    });
  }

  create(entity: Partial<T>): Promise<T> {
    return fetch(`${config.apiRoot}/${this.path}`, {
      method: "POST",
      body: JSON.stringify(entity),
    }).then((resp) => resp.json());
  }
}
