import { createFormContext } from "@mantine/form";
import { Workforce } from "./workforce.model";

export const [
  WorkforceFormProvider,
  useWorkforceFormContext,
  useWorkforceForm,
] = createFormContext<Omit<Workforce, "id">>();

export const defaultWorkforce: Omit<Workforce, "id"> = {
  name: "",
  players: [],
}
