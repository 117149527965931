import { useState, useMemo, useEffect, useContext } from "react"
import { TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { WallContext } from "wall/hooks/useWall"

import type { Message } from "wall/types/message.model"

type PseudoInputProps = {
  form: UseFormReturnType<Partial<Message>>
  inputValueName: string
  maxChar: number
  minChar: number
}

export function PseudoInput({
  form,
  inputValueName,
  maxChar,
  minChar,
}: PseudoInputProps) {
  const wall = useContext(WallContext)
  const [charCount, setCharCount] = useState(0)

  const isReadOnly = useMemo(
    () => charCount === maxChar + 1,
    [charCount, maxChar]
  )

  useEffect(() => {
    const inputValue = form.getInputProps(inputValueName).value
    const countChar = inputValue.length
    if (countChar > maxChar) {
      form.setFieldValue(
        inputValueName,
        inputValue.toString().substring(0, maxChar)
      )
      return
    }
    setCharCount(countChar)
  }, [form, form.values, inputValueName, maxChar])

  return (
    <TextInput
      key={inputValueName}
      label={"Pseudo"}
      minLength={minChar}
      maxLength={maxChar}
      name={inputValueName}
      radius={"xs"}
      required
      readOnly={isReadOnly}
      styles={{
        label: {
          fontFamily: "Team-A-Bold",
          fontSize: "1rem",
          fontWeight: "bold",
          textTransform: "uppercase",
          color: wall?.colors?.labels,
          span: "blue",
        },
        input: {
          fontFamily: "Team-A-Regular",
          fontWeight: "normal",
        },
        required: { color: wall?.colors?.mandatory },
      }}
      type="text"
      rightSection={
        <span
          style={{ color: wall?.colors?.mandatory }}
        >{`${charCount}/${maxChar}`}</span>
      }
      onChange={(e) => console.log("Event", e)}
      {...form.getInputProps(inputValueName)}
    />
  )
}
