import React, { createContext, useCallback, useMemo, useState } from "react"
import { DEBUG_MODE } from "config"

interface DebugContextType {
  isDebugMode: boolean
  toggleDebugMode: () => void
  log: (...args: any[]) => void
}

export const DebugContext = createContext<DebugContextType | undefined>(
  undefined
)

export function DebugProvider({ children }: { children: React.ReactNode }) {
  const [isDebugMode, setIsDebugMode] = useState(DEBUG_MODE)

  const toggleDebugMode = useCallback(() => {
    setIsDebugMode((prev) => !prev)
  }, [])

  const log = useCallback(
    (...args: any[]) => {
      if (isDebugMode) {
        console.log("[DEBUG]", ...args)
      }
    },
    [isDebugMode]
  )

  const value = useMemo(
    () => ({
      isDebugMode,
      toggleDebugMode,
      log,
    }),
    [isDebugMode, toggleDebugMode, log]
  )

  return <DebugContext.Provider value={value}>{children}</DebugContext.Provider>
}
