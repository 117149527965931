import { useParams } from "react-router-dom"

import { useGetEventQuery } from "event/api/event.api"
import { EventDate } from "event/play/components/EventDate"
import { EVENT_HEADER_FIRST_OPPONENT } from "_constants"

const EventHomepageHeader = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const getEventQuery = useGetEventQuery(eventId as string)
  const event = getEventQuery.data

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "480px",
        paddingTop: "20px",
      }}
    >
      <img
        src="/psg/event_logo.png"
        alt="Logo"
        style={{ width: "30%", marginBottom: "20px", zIndex: 0 }}
      />
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#e30613",
          color: "white",
          display: "flex",
          fontFamily: "Team-A-Bold",
          fontSize: "12px",
          justifyContent: "space-between",
          padding: "10px",
          textAlign: "start",
          width: "100%",
        }}
      >
        <div className="flex flex-col w-9/12 border-r-2 border-white">
          <span>{EVENT_HEADER_FIRST_OPPONENT}</span>
          <span>{event?.opponent.name.toUpperCase()}</span>
        </div>
        {event ? <EventDate className="w-3/1" event={event} /> : null}
      </div>
    </div>
  )
}

export { EventHomepageHeader }
