import { Anchor, Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

import type { Event } from "event/types/event.model";

function EventBreadcrumbs({ event }: { event?: Event | "new" }) {
    return (
        <Breadcrumbs separator="→">
            <Anchor component={Link} to={"/admin"}>
                Admin
            </Anchor>
            {event ? (
                <Anchor component={Link} to={"/admin/events"}>
                    Événements
                </Anchor>
            ) : (
                <span>Événements</span>
            )}
            {event === "new" && (
                <span>Nouvel événement</span>
            )}
        </Breadcrumbs>
    );
}

export default EventBreadcrumbs;
