import { useLocalStorage } from "@mantine/hooks"
import { useMemo } from "react"

import { SavedGame } from "quiz/types/saved.game.model"
import type { PlayerFormFields } from "common/form/PlayerTypes"

export const useSavedGame = (gameId: string) => {
  const [savedGames, setSavedGames] = useLocalStorage<
    Record<string, SavedGame>
  >({
    key: "games",
    getInitialValueInEffect: false,
  })

  return useMemo(
    () =>
      [
        (savedGames || {})[gameId],
        (
          playerId: string,
          partitionId: string,
          playerInfo: Omit<Partial<PlayerFormFields>, "termsOfService">
        ) =>
          setSavedGames((games) => ({
            ...games,
            [gameId]: { playerId, partitionId, playerInfo },
          })),
      ] as const,

    [savedGames, setSavedGames, gameId]
  )
}
