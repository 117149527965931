import { useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { Container, Text } from "@mantine/core"

import { QUIZ_GAME_NOT_CONNECTED } from "_constants"

import type { Event } from "event/types/event.model"
import type { GameState } from "quiz/types/game.model"
import { quizMode } from "quiz/types/quiz.model"
import { useSavedEvent } from "event/hooks/useSavedEvent"
import { useSavedGame } from "quiz/hooks/useSavedGame"
import { useGetPlayerQuery } from "quiz/api/players.endpoints"
import { useGame } from "quiz/hooks/useGame"

import { PlayLoader } from "quiz/play/components/PlayLoader"
import { PlayBlindtestView } from "quiz/play/views/blindtest/PlayBlindtestView"
import { PlayQuizView } from "quiz/play/views/quiz/PlayQuizView"
import { withDebug } from "common/debug/components/withDebug"

function PlayGamePresenterComponent() {
  const game = useGame()
  const location = useLocation()
  const _state = location.state as { event?: Event } | undefined
  const event = _state?.event
  const savedEvent = useSavedEvent(event?.id || "")[0]

  const { gameId } = useParams()
  const [savedGame, setSavedGame] = useSavedGame(gameId!)
  const [gameState, setGameState] = useState<GameState | undefined>()

  let playerId: string
  let partitionId: string

  if (savedEvent) {
    ;({ playerId, partitionId } = savedEvent)
  } else {
    ;({ playerId, partitionId } = savedGame || {})
  }

  const { data: playerEventData } = useGetPlayerQuery([playerId, partitionId])
  if (game && game.quizSnapshot.mode === quizMode["Choix multiples"]) {
    return (
      <PlayQuizView
        eventId={event?.id}
        gameState={gameState}
        setGameState={setGameState}
        savedEvent={savedEvent}
        savedGame={savedGame}
        setSavedGame={setSavedGame}
        gameId={gameId}
        game={game}
        playerEventData={playerEventData}
      />
    )
  }

  if (game && game.quizSnapshot.mode === quizMode["Blindtest"]) {
    return (
      <PlayBlindtestView
        eventId={event?.id}
        gameState={gameState}
        setGameState={setGameState}
        savedEvent={savedEvent}
        savedGame={savedGame}
        setSavedGame={setSavedGame}
        gameId={gameId}
        game={game}
        playerEventData={playerEventData}
      />
    )
  }

  return (
    <Container pb={"lg"}>
      <PlayLoader
        message={
          <Text
            className="text-2xl text-center font-team-a-bold"
            sx={{
              color: "#ffffff",
            }}
            dangerouslySetInnerHTML={{ __html: QUIZ_GAME_NOT_CONNECTED }}
          />
        }
      />
    </Container>
  )
}

export const PlayGamePresenter = withDebug(PlayGamePresenterComponent)