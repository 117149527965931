import { Box, Title, Divider } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import ImagesForm from "common/admin/forms/ImagesForm"
import type { Quiz } from "quiz/types/quiz.model"

export function QuizImagesFormInput({
  form,
}: {
  form: UseFormReturnType<Omit<Quiz, "id">>
}) {
  return (
    <>
      <Box>
        <Title order={4}>Tous supports</Title>
        <ImagesForm
          form={form}
          fields={[
            ["waitingLogo", "Image d'attente pendant le quiz"],
            ["gameOverLogo", "Image pour la fin du jeu"],
            ["questionLogo", "Image pendant quiz"],
            ["winnerLogo", "Image pour le gagnant"],
          ]}
        />
      </Box>
      <br />
      <Divider my="sm" />
      <br />
      <Box>
        <Title order={4}>Ecran</Title>
        <ImagesForm
          form={form}
          fields={[
            ["screenBackground", "Arrière-plan"],
            ["screenBackgroundLeft", "Arrière-plan gauche"],
            ["screenAnimatedLogo", "Image animé pendant le quiz"],
          ]}
        />
      </Box>
      <br />
      <Divider my="sm" />
      <br />
      <Box>
        <Title order={4}>Mobile</Title>
        <ImagesForm
          form={form}
          fields={[
            ["playBackground", "Arrière-plan"],
            ["registerLogo", "Image de l'écran d'inscription"],
          ]}
        />
      </Box>
    </>
  )
}
