import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import type { Wall } from "wall/types/wall.model"

const wallApi = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Wall", Wall>("Wall", "walls"),
})

export const {
  useGetWallQuery,
  useListWallsQuery,
  useAddWallMutation,
  useRemoveWallMutation,
  useUpdateWallMutation,
} = wallApi
