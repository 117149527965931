import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { apiSlice } from "core/api";
import { makeCrudEndpoints, makeEndpoint } from "common/api/apiHelpers";
import type { Player } from "quiz/types/player.model";

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Player", Player>("Player", "players"),
}).injectEndpoints({
  endpoints: (build: EndpointBuilder<any, "Player", any>) => makeEndpoint(
    "getPlayer",
    build.query<Player, [string, string]>({
      query: ([playerId, partitionId]) => ({
        method: "GET",
        url: `players/${partitionId}/${playerId}`,
      }),
      providesTags: (result, error, [partitionId, playerId]) => [{ type: "Player", id: `${partitionId}/${playerId}` }],
    })
  ),
  overrideExisting: true,
});

export const {
  useGetPlayerQuery,
  useListPlayersQuery,
  useAddPlayerMutation,
  useUpdatePlayerMutation,
} = api;
