import { createContext } from "react"
import { useParams } from "react-router-dom"

import { useGetWallQuery } from "wall/api/walls.endpoints"
import type { Wall } from "wall/types/wall.model"

function useWall() {
  const { wallId } = useParams<{ wallId: string }>()
  return useGetWallQuery(wallId!).data
}

const WallContext = createContext<Partial<Wall>>({})

export { WallContext, useWall }
