import { Outlet, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import QueryWrapper from "common/components/QueryWrapper"
import { PlayLayout } from "common/layouts/PlayLayout"
import { useGetGameQuery } from "quiz/api/games.endpoints"

export function GamePlayThemeWrapper() {
  const { gameId } = useParams()
  const getGameQuery = useGetGameQuery(gameId || skipToken)

  const defaultGameTheme = {
    colors: {
      background: "#131527",
      text: "#FFFFFF",
    },
    images: {
      playBackground: "/psg/background_mobile.png",
    },
  }

  return (
    <QueryWrapper query={getGameQuery}>
      {(game) => (
        <PlayLayout theme={game ? game.quizSnapshot : defaultGameTheme}>
          <Outlet />
        </PlayLayout>
      )}
    </QueryWrapper>
  )
}
