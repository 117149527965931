import { useContext } from "react"
import { Button, Image } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { WALL_BUTTON } from "_constants"
import { WallContext } from "wall/hooks/useWall"
import type { Message } from "wall/types/message.model"

interface MessageSentProps {
  form: UseFormReturnType<
    Partial<Message>,
    (values: Partial<Message>) => Partial<Message>
  >
  setIsMessageSent: React.Dispatch<React.SetStateAction<boolean>>
}

export function MessageSent({ form, setIsMessageSent }: MessageSentProps) {
  const wall = useContext(WallContext)

  return (
    <>
      <Image
        src={wall?.images?.confirmLogo}
        alt="Confirm Logo"
        width={65}
        sx={{
          paddingTop: "3rem",
        }}
      />
      <span
        style={{
          color: "white",
          fontSize: "1.5rem",
          fontFamily: "Team-A-Bold",
          textTransform: "uppercase",
          flex: "calc(2/3) 1 0",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {wall.sentMessage
          ? wall.sentMessage
          : "Votre message a bien été envoyé !"}
      </span>
      <Button
        type="submit"
        radius={"xs"}
        styles={{
          root: {
            borderWidth: "2px",
            borderColor: wall?.colors?.buttonBorder,
            color: wall?.colors?.buttonText,
            padding: 0,
            width: "18rem",
          },
          inner: {
            backgroundColor: wall?.colors?.buttonBackground,
            fontSize: "1rem",
            textTransform: "uppercase",
            width: "100%",
          },
        }}
        onClick={() => {
          setIsMessageSent(false)
          form.setFieldValue("message", "")
        }}
      >
        <span>{wall.sentButtonLabel ? wall.sentButtonLabel : WALL_BUTTON}</span>
      </Button>
    </>
  )
}
