import React from "react"
import type { UseFormReturnType } from "@mantine/form"

import { useQuiz } from "quiz/hooks/useQuiz"
import EntityToolbar from "common/admin/EntityToolbar"
import {
  useAddQuizMutation,
  useRemoveQuizMutation,
  useUpdateQuizMutation,
} from "quiz/api/quizzes.endpoints"

import type { Quiz } from "quiz/types/quiz.model"

type QuizToolbarProps = {
  form: UseFormReturnType<Omit<Quiz, "id">>
}

export function QuizToolbar({ form }: QuizToolbarProps) {
  const quiz = useQuiz()
  const addQuizMutation = useAddQuizMutation()
  const updateQuizMutation = useUpdateQuizMutation()
  const removeQuizMutation = useRemoveQuizMutation()

  return (
    <EntityToolbar
      entity={quiz}
      form={form}
      addMutation={addQuizMutation}
      updateMutation={updateQuizMutation}
      removeMutation={removeQuizMutation}
      label="quiz"
    />
  )
}
