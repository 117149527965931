import { motion, useTime } from "framer-motion"
import { useMemo, useState } from "react"

export function CoubertinTimer({
  color,
  duration,
  initialDelay,
}: {
  color: string
  duration: number
  initialDelay: number
}) {
  const remaining = useTime()
  const [countdown, setCountdown] = useState(remaining.get())
  remaining.onChange((value) =>
    setCountdown(duration + initialDelay - value / 1000)
  )

  const displayedCountdown = useMemo(() => {
    if (countdown > 0) {
      return Math.round(countdown)
    }
    return 0
  }, [countdown])

  return (
    <motion.div
      variants={{
        initial: { scale: 0 },
        choices: { scale: 1 },
        solution: { scale: 0, marginLeft: "-6%", transition: { delay: 1 } },
      }}
    >
      <motion.svg
        viewBox="0 0 100 100"
        variants={{
          initial: { width: "0" },
          choices: { width: "200px" },
          solution: { width: 0 },
        }}
        style={{ height: "300px" }}
      >
        <motion.circle
          variants={{
            initial: { pathLength: 1 },
            choices: {
              pathLength: 0,
              transition: { duration, ease: "linear" },
            },
          }}
          transition={{ duration: 5 }}
          transform={"rotate(-90 50 50)"}
          cx={50}
          strokeWidth={5}
          stroke={color}
          fill={"none"}
          cy={50}
          r={45}
        />
        <motion.circle
          transform={"rotate(-90 50 50)"}
          cx={50}
          fill={"none"}
          cy={50}
          variants={{
            initial: {
              strokeWidth: 50,
              r: 25,
              stroke: `${color}`,
            },
            choices: {
              strokeWidth: 5,
              r: 45,
              stroke: `${color}`,
              transition: { delay: 1 },
            },
          }}
        />
        <motion.text
          x={50}
          y={55}
          fontSize={72}
          textAnchor={"middle"}
          dominantBaseline={"middle"}
          variants={{
            initial: { fill: "black" },
            choices: { fill: `${color}`, transition: { delay: 1 } },
          }}
        >
          {displayedCountdown}
        </motion.text>
      </motion.svg>
    </motion.div>
  )
}
