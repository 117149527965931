import { UnstyledButton, Group, Center, Text, ThemeIcon } from "@mantine/core"
import { ChevronUp, ChevronDown, Selector } from "tabler-icons-react"

interface ThProps {
  children: React.ReactNode
  reversed: boolean
  sorted: boolean
  onClick: () => void
}

const Th = ({ children, reversed, sorted, onClick }: ThProps) => {
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector
  return (
    <th>
      <UnstyledButton onClick={onClick}>
        <Group>
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center>
            <ThemeIcon size="sm">
              <Icon size="1.2rem" />
            </ThemeIcon>
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  )
}

export { Th }
