import { PSGHeader } from "event/play/components/PSGHeader"
import { PDFViewer } from "./PDFViewer"
import { useGetFoodMenuQuery } from "click-and-collect/api/foodMenu.api"

interface ClickAndCollectPDFViewProps {
  foodMenuId: string
}

export function ClickAndCollectPDFView({
  foodMenuId,
}: ClickAndCollectPDFViewProps) {
  const { data, isSuccess } = useGetFoodMenuQuery(foodMenuId!)

  return (
    <>
      <PSGHeader />
      {isSuccess && data && data.document ? (
        <PDFViewer pdfFileUrl={data.document} />
      ) : null}
    </>
  )
}
