import { forwardRef } from "react";
import { Group, Select, SelectProps } from "@mantine/core";
import {
  CircleCheck,
  CircleDot,
  CircleDotted,
  CircleX,
  Filter,
} from "tabler-icons-react";
import { MessageState } from "./MessagesList";

const SelectItem = forwardRef<
  HTMLDivElement,
  {
    baseLabel: string;
    count: number;
    icon: React.ReactNode;
  } & React.ComponentPropsWithoutRef<"div">
>(({ baseLabel, count, icon, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group>
      {icon}
      <Group position={"apart"} className={"grow"}>
        <div>{baseLabel}</div>
        <div className={"font-bold"}>{count}</div>
      </Group>
    </Group>
  </div>
));

function MessageStateSelect({
  counts,
  onChange,
  ...props
}: Omit<Partial<SelectProps>, "onChange"> & {
  counts: Record<MessageState, number>;
  onChange: (state: MessageState) => void;
}) {
  return (
    <Select
      icon={<Filter size={16} />}
      itemComponent={SelectItem}
      size={"sm"}
      sx={{ width: 275 }}
      onChange={(value: MessageState) => onChange(value)}
      data={[
        {
          value: "all",
          label: "Tous les messages",
          icon: <CircleDotted />,
        },
        {
          value: "unset",
          label: "Messages non triés ",
          icon: <CircleDot />,
        },
        {
          value: "approved",
          label: "Messages acceptés",
          icon: <CircleCheck />,
        },
        {
          value: "rejected",
          label: "Messages rejetés ",
          icon: <CircleX />,
        },
      ].map((t) => ({
        ...t,
        count: counts[t.value as keyof typeof counts],
        baseLabel: t.label,
        label: `${t.label} (${counts[t.value as keyof typeof counts]})`,
      }))}
      {...props}
    />
  );
}

export default MessageStateSelect;
