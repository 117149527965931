import { Box, Button, Group, Stack, Table, Text, Title } from "@mantine/core"
import { useNavigate } from "react-router-dom"

import {
  ADMIN_ORGANIZATION,
  DIAPORAMA_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_CREATE_AUTHORISED_ROLES,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useOrganization } from "common/admin/auth/hooks"
import Collapsable from "common/admin/ui/Collapsable"
import QueryWrapper from "common/components/QueryWrapper"
import { useListWallsQuery } from "wall/api/walls.endpoints"
import { WallBreadcrumbs } from "wall/admin/components/WallBreadcrumbs"

export default function WallsView() {
  const navigate = useNavigate()
  const listWallsQuery = useListWallsQuery()
  const [organization] = useOrganization()

  return (
    <Box>
      <WallBreadcrumbs />

      <Title order={3} align={"center"} m={"lg"}>
        Liste des diaporamas
      </Title>

      <AccessRights
        authorizedOrganizations={DIAPORAMA_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={DIAPORAMA_CREATE_AUTHORISED_ROLES}
      >
        <Group position={"right"}>
          <Button className="bg-button-admin" onClick={() => navigate("new")}>
            Nouveau diaporama
          </Button>
        </Group>
      </AccessRights>
      <Collapsable title={"Liste des diaporamas"}>
        <QueryWrapper query={listWallsQuery}>
          {(sessions) =>
            sessions.length ? (
              <Table highlightOnHover>
                <tbody>
                  {sessions!.map((wall) =>
                    (wall.organization && wall.organization === organization) ||
                    organization === ADMIN_ORGANIZATION ? (
                      <tr
                        key={wall.id}
                        onClick={() =>
                          navigate(`/admin/walls/${wall.id}`, {
                            state: { wall },
                          })
                        }
                        style={{ display: "table-row", cursor: "pointer" }}
                      >
                        <td>
                          <Text variant="link">
                            {wall.name || `Quiz #${wall.id.split("-")[0]}`}
                          </Text>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </Table>
            ) : (
              <Stack align={"center"}>
                <Box>Aucune session trouvé</Box>
                <AccessRights
                  authorizedOrganizations={DIAPORAMA_AUTHORISED_ORGANIZATIONS}
                  authorizedRoles={DIAPORAMA_CREATE_AUTHORISED_ROLES}
                >
                  <Button
                    className="bg-button-admin"
                    onClick={() => navigate("new")}
                  >
                    Nouveau diaporama
                  </Button>
                </AccessRights>
              </Stack>
            )
          }
        </QueryWrapper>
      </Collapsable>
    </Box>
  )
}
