import { Link } from "react-router-dom"
import { Card, Group, Stack, Title } from "@mantine/core"
import {
  CalendarEvent,
  DoorExit,
  PlayerPlay,
  QuestionMark,
  Run,
} from "tabler-icons-react"

import {
  CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_AUTHORISED_ROLES,
  DIAPORAMA_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_AUTHORISED_ROLES,
  EVENT_AUTHORISED_ORGANIZATIONS,
  EVENT_AUTHORISED_ROLES,
  QUIZ_AUTHORISED_ORGANIZATIONS,
  QUIZ_AUTHORISED_ROLES,
  WORKFORCE_AUTHORISED_ORGANIZATIONS,
  WORKFORCE_AUTHORISED_ROLES,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useQuizToken, useRole } from "common/admin/auth/hooks"

import foodMenu from "./food-menu.png"

export function AdminMenuView() {
  const [, setQuizToken] = useQuizToken()
  const [, setRole] = useRole()

  return (
    <Stack className="h-full" my="xl">
      <AccessRights
        authorizedRoles={QUIZ_AUTHORISED_ROLES}
        authorizedOrganizations={QUIZ_AUTHORISED_ORGANIZATIONS}
      >
        <Link to="quizzes" className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <QuestionMark size={50} />
              <Title order={3}>Quizzes</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedRoles={DIAPORAMA_AUTHORISED_ROLES}
        authorizedOrganizations={DIAPORAMA_AUTHORISED_ORGANIZATIONS}
      >
        <Link to="walls" className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <PlayerPlay size={50} />
              <Title order={3}>Diaporamas</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedRoles={WORKFORCE_AUTHORISED_ROLES}
        authorizedOrganizations={WORKFORCE_AUTHORISED_ORGANIZATIONS}
      >
        <Link to="workforces" className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <Run size={50} />
              <Title order={3}>Effectifs</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedRoles={EVENT_AUTHORISED_ROLES}
        authorizedOrganizations={EVENT_AUTHORISED_ORGANIZATIONS}
      >
        <Link to="events" className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <CalendarEvent size={50} />
              <Title order={3}>Événements</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedRoles={CLICK_AND_COLLECT_AUTHORISED_ROLES}
        authorizedOrganizations={CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS}
      >
        <Link to="clickandcollect" className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <img
                src={foodMenu}
                alt="Click And Collect"
                style={{ width: 50, height: 50 }}
              />
              <Title order={3}>Click and Collect</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <div
        onClick={() => {
          setQuizToken(null)
          setRole(null)
        }}
        className="cursor-pointer"
      >
        <Card style={{ flex: 1 }}>
          <Group spacing="xl">
            <DoorExit size={50} />
            <Title order={3}>Déconnexion</Title>
          </Group>
        </Card>
      </div>
    </Stack>
  )
}
