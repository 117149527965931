import { Anchor, Button, Modal, Text, TextInput } from "@mantine/core"
import { Check, X } from "tabler-icons-react"
import { v4 as uuidv4 } from "uuid"

import MutationWrapper from "common/components/MutationWrapper"
import MessageImage from "./MessageImage"
import type { Message } from "wall/types/message.model"

const MessageRowSelectionLabel = ({
  author,
  imageKey,
  imageKeyApproved,
  index,
  onClick,
}: {
  author: string
  imageKey?: string
  imageKeyApproved?: boolean
  index: number
  onClick: VoidFunction
}) => {
  return (
    <div className="flex justify-between">
      <span className="mr-5">{`#${index + 1} Auteur: ${author}`}</span>
      {imageKey ? (
        <MessageImage imageKey={imageKey} imageKeyApproved={imageKeyApproved} />
      ) : null}
      {imageKey && imageKeyApproved === false ? (
        <X color="red" className="ml-2" onClick={onClick} />
      ) : imageKey &&
        (imageKeyApproved === true || imageKeyApproved === undefined) ? (
        <Check color="green" className="ml-2" onClick={onClick} />
      ) : null}
    </div>
  )
}

type MessagesEditModalProps = {
  uploadURL: string | null
  setUploadURL: React.Dispatch<React.SetStateAction<string | null>>
  isEditingModalOpen: boolean
  setIsEditingModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  getPresignedPostResult: any // TODO
  uploadToS3Result: any // TODO
  editedMessages: Message[]
  setEditedMessages: React.Dispatch<React.SetStateAction<Message[]>>
  onSubmit: VoidFunction
}

const MessagesEditModal = ({
  uploadURL,
  setUploadURL,
  isEditingModalOpen,
  setIsEditingModalOpen,
  getPresignedPostResult,
  uploadToS3Result,
  editedMessages,
  setEditedMessages,
  onSubmit,
}: MessagesEditModalProps) => {
  const toggleImageApproval = (index: number) => {
    const messagesToUpdate = [...editedMessages]
    messagesToUpdate[index] = {
      ...editedMessages[index],
      imageKeyApproved: !editedMessages[index].imageKeyApproved,
    }
    setEditedMessages(messagesToUpdate)
  }

  return (
    <Modal
      title={uploadURL ? "Upload réussi" : "Éditer les messages"}
      opened={isEditingModalOpen}
      size="xl"
      closeOnClickOutside={false}
      onClose={() => {
        setIsEditingModalOpen(false)
        setUploadURL(null)
      }}
    >
      {uploadURL ? (
        <Text className="place-content-center">
          Merci, votre XML a été uploadé et est disponible à l'adresse suivante
          :<br />
          <Anchor target="_blank" rel="noreferrer" href={uploadURL}>
            {uploadURL}
          </Anchor>
        </Text>
      ) : (
        <>
          <MutationWrapper
            message="Impossible d'uploader le fichier XML."
            title="Erreur"
            variant="outline"
            withDetails={true}
            result={getPresignedPostResult}
          />
          <MutationWrapper
            message="Impossible d'uploader le fichier XML."
            title="Erreur"
            variant="outline"
            withDetails={true}
            result={uploadToS3Result}
          />
          {editedMessages.map((msgObj, index) => (
            <div key={uuidv4()} style={{ marginBottom: "15px" }}>
              <TextInput
                label={
                  <MessageRowSelectionLabel
                    author={msgObj.author}
                    imageKey={msgObj.imageKey}
                    imageKeyApproved={msgObj.imageKeyApproved}
                    index={index}
                    onClick={() => toggleImageApproval(index)}
                  />
                }
                value={msgObj.message}
                onChange={(event) => {
                  const newMessages = editedMessages.map((msg, idx) => {
                    if (idx === index) {
                      return {
                        ...msg,
                        message: event.target.value,
                      }
                    }
                    return msg
                  })
                  setEditedMessages(newMessages)
                }}
              />
            </div>
          ))}
          <Button
            className="bg-button-admin"
            onClick={onSubmit}
            style={{ marginTop: "10px" }}
          >
            Générer le XML
          </Button>
        </>
      )}
    </Modal>
  )
}

export { MessagesEditModal }
