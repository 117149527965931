import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { config } from "config";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiRoot,
    prepareHeaders(headers) {
      if (!headers.has("Authorization")) {
        headers.set(
          "Authorization",
          "Bearer " + JSON.parse(sessionStorage.getItem("quizToken")!)
        );
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: 5,
  endpoints: () => ({}),
});
