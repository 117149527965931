import { useMemo } from "react"
import { useParams } from "react-router-dom"
import QRCode from "react-qr-code"
import { Box, Group, Image, Title } from "@mantine/core"

import type { Game, GameState } from "quiz/types/game.model"
import { getQuizPlayUrl } from "common/utils/routeHelpers"
import { CoubertinQuestion } from "quiz/screencast/components/CoubertinQuestion"
import { ParcDesPrincesQuestion } from "quiz/screencast/components/ParcDesPrincesQuestion"
import {
  BLINDTEST_WAITING_MSG,
  BLINDTEST_WAITING_RESULT_MSG,
  QUIZ_GAME_RESULT_CALCULATION,
  QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG,
} from "_constants"
import { Quiz, quizScreenType } from "quiz/types/quiz.model"

export function RunningBlindtestGame({
  backgroundSize,
  game,
  gameState,
  quiz,
  screenType,
}: {
  backgroundSize: string
  game: Game
  gameState: GameState
  quiz: Quiz
  screenType: quizScreenType
}) {
  const { gameId } = useParams()

  const url = useMemo(() => getQuizPlayUrl(gameId!), [gameId])
  const totalQuestions = useMemo(() => quiz.questions.length, [quiz])

  const question = useMemo(() => {
    if (
      gameState.questionIdx != null &&
      game?.quizSnapshot.questions[gameState.questionIdx]
    ) {
      return game.quizSnapshot.questions[gameState.questionIdx]
    }
  }, [game?.quizSnapshot.questions, gameState.questionIdx])

  const Question =
    screenType === "bandeau_horizontal"
      ? CoubertinQuestion
      : ParcDesPrincesQuestion

  const winner = useMemo<string>(() => {
    if (
      gameState &&
      gameState.leaderboard &&
      gameState.leaderboard.length > 0
    ) {
      return gameState.leaderboard[0].firstName
    }
    return ""
  }, [gameState])

  const winnerMessage = useMemo(() => {
    if (game && winner.length > 0) {
      return {
        __html: game.quizSnapshot.endingMsg.replace("#WINNER#", winner).trim(),
      }
    }
    return { __html: QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG }
  }, [game, winner])

  const headTitle = useMemo(() => {
    if (
      gameState?.questionStatus === "NEXT" &&
      typeof gameState.questionIdx === "number" &&
      gameState.questionIdx === totalQuestions - 1
    ) {
      return BLINDTEST_WAITING_RESULT_MSG
    }
    if (
      gameState?.questionStatus === "NEXT" &&
      typeof gameState.questionIdx === "number" &&
      gameState.questionIdx < totalQuestions
    ) {
      return BLINDTEST_WAITING_MSG
    }

    return ""
  }, [gameState, totalQuestions])

  if (
    gameState?.questionStatus === "NEXT" &&
    typeof gameState.questionIdx === "number"
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          margin: "0 auto",
          paddingBottom: "2vh",
        }}
      >
        <h2
          style={{
            fontSize: "36px",
            fontFamily: "QuizCustomFont",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: headTitle }}
        ></h2>
      </div>
    )
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: game.quizSnapshot.colors.background,
        backgroundImage: `url(${game.quizSnapshot.images.screenBackground})`,
        backgroundSize,
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {gameState.step === "QUESTION" && question ? (
        <Question
          gameState={gameState}
          question={question}
          quiz={game!.quizSnapshot}
        />
      ) : null}

      {gameState.step === "GAME_OVER" &&
      game.quizSnapshot.screenType === "bandeau_horizontal" ? (
        <h1
          style={{
            fontSize: "36px",
            fontFamily: "QuizCustomFont",
          }}
        >
          {QUIZ_GAME_RESULT_CALCULATION}
        </h1>
      ) : null}

      {gameState.step === "GAME_OVER" &&
      game.quizSnapshot.screenType === "16_9" ? (
        <Group>
          <Box mx={"xl"}>
            <Image
              src={quiz.images.gameOverLogo}
              height={200}
              alt={"GameOverLogo"}
            />
          </Box>
          <h1
            style={{
              textAlign: "center",
              fontSize: "36px",
              fontFamily: "QuizCustomFont",
            }}
          >
            {QUIZ_GAME_RESULT_CALCULATION}
          </h1>
        </Group>
      ) : null}

      {gameState.step === "RESULTS" && winner.length > 0 && winnerMessage ? (
        <Group>
          <Box mx={"xl"}>
            <Image
              src={game?.quizSnapshot!.images.gameOverLogo}
              height={200}
              alt={"Game Over logo"}
            />
          </Box>
          <h1
            style={{
              textAlign: "center",
              fontSize: "36px",
              fontFamily: "QuizCustomFont",
            }}
            dangerouslySetInnerHTML={winnerMessage}
          />
        </Group>
      ) : null}

      {gameState.step === "CREATED" && (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {game!.quizSnapshot.screenType === "bandeau_horizontal" ? (
            <>
              <div style={{ background: "white", padding: "8px" }}>
                <QRCode
                  size={200}
                  fgColor={game.quizSnapshot.colors.background}
                  bgColor={game.quizSnapshot.colors.questionText}
                  value={url}
                />
              </div>

              <Title
                sx={{
                  color: game.quizSnapshot.colors.questionText,
                  fontFamily: "QuizCustomFont",
                }}
              >
                {game.quizSnapshot.waitingMsg}
              </Title>
            </>
          ) : null}
          {game.quizSnapshot.screenType === "16_9" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8rem",
                }}
              >
                <Image
                  src={quiz.images.waitingLogo}
                  style={{
                    width: "40vw",
                  }}
                  alt="Waiting Logo"
                />
                <Title
                  sx={{
                    color: game.quizSnapshot.colors.questionText,
                    fontFamily: "QuizCustomFont",
                    fontSize: "36px",
                  }}
                >
                  {game!.quizSnapshot.waitingMsg}
                </Title>
              </Box>

              <div style={{ background: "white", padding: "16px" }}>
                <QRCode
                  size={600}
                  fgColor={game.quizSnapshot.colors.background}
                  bgColor={game.quizSnapshot.colors.questionText}
                  value={url}
                />
              </div>
            </>
          ) : null}
        </Box>
      )}
    </Box>
  )
}
