import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useId } from "@mantine/hooks"
import { openModal } from "@mantine/modals"

import { RemoveEntityModal } from "common/components/RemoveEntityModal"

export const useOpenRemoveModal = (
  title: string,
  remove: () => Promise<unknown>,
  nextUrl: string
) => {
  const navigate = useNavigate()
  const modalId = useId()

  return useCallback(
    () =>
      openModal({
        modalId,
        title,
        withCloseButton: false,
        children: (
          <RemoveEntityModal
            modalId={modalId}
            remove={remove}
            onRemoved={() => navigate(nextUrl)}
          />
        ),
      }),
    [modalId, navigate, nextUrl, remove, title]
  )
}
