import React from "react";
import useWorkforce from "./useWorkforce";
import { useWorkforceFormContext } from "./form-context";
import EntityToolbar from "../../common/admin/EntityToolbar";
import {
  useAddWorkforceMutation,
  useRemoveWorkforceMutation,
  useUpdateWorkforceMutation,
} from "./workforce.api";

function WorkforceToolbar() {
  const workforce = useWorkforce();
  const form = useWorkforceFormContext();

  const addWorkforceMutation = useAddWorkforceMutation();
  const updateWorkforceMutation = useUpdateWorkforceMutation();
  const removeWorkforceMutation = useRemoveWorkforceMutation();

  return (
    <EntityToolbar
      entity={workforce}
      form={form}
      addMutation={addWorkforceMutation}
      updateMutation={updateWorkforceMutation}
      removeMutation={removeWorkforceMutation}
      label="effectif"
    />
  );
}

export default WorkforceToolbar;
