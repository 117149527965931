import { apiSlice } from "core/api"
import type { LoginRequestInput, LoginResponseOutput } from "../types"

const api = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponseOutput, LoginRequestInput>({
      query: (body) => ({
        method: "POST",
        url: "login",
        body,
      }),
    }),
  }),
})

export const { useLoginMutation } = api
