export enum wallTemplate {
  "Bandeau horizontal (1540 x 220)" = "bandeau_horizontal",
  "16/9 (1920 x 1080)" = "16_9",
}
export interface Wall {
  colors: {
    authors: string
    background: string
    buttonBackground: string
    buttonBorder: string
    buttonText: string
    info: string
    labels: string
    mandatory: string
    messages: string
    upload: string
  }
  entity: string
  id: string
  images: {
    screenBackground: string
    playBackground: string
    formLogo: string
    confirmLogo: string
  }
  msgAuthorFontSize: string
  msgFontSize: string
  name: string
  organization: string
  pageTitle: string
  section: string
  template: wallTemplate
  type: "TEXT" | "IMAGES" | "HYBRID"
  welcomeMsg: string
  labelFormMessage?: string
  sentMessage?: string
  sentButtonLabel?: string
}
