import { useParams } from "react-router-dom"
import { Container } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import { useGetEventQuery } from "event/api/event.api"
import { ClickAndCollectPDFView } from "./ClickAndCollectPDFView"

export function ClickAndCollectPDFPresenter() {
  const { eventId } = useParams<{ eventId: string }>()
  const { data, isSuccess } = useGetEventQuery(eventId as string)

  const styles = {
    desktopBackground: {
      backgroundImage: "url(/psg/background_desktop.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    mobileBackground: {
      backgroundImage: "url(/psg/background_mobile.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxWidth: 768
    },
  }

  const { width } = useViewportSize()
  const isMobile = width <= 768
  const currentStyle = isMobile
    ? styles.mobileBackground
    : styles.desktopBackground

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        minHeight: "100dvh",
        maxWidth: "none",
        textAlign: "center",
        paddingTop: "2.5%",
        ...currentStyle,
      }}
    >
      {isSuccess && data && data.food_menu ? (
        <ClickAndCollectPDFView foodMenuId={data.food_menu} />
      ) : null}
    </Container>
  )
}
