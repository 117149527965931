import { Anchor, Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

import { Workforce } from "./workforce.model";

function WorkforceBreadcrumbs({ workforce }: { workforce?: Workforce | "new" }) {
    return (
        <Breadcrumbs separator="→">
            <Anchor component={Link} to={"/admin"}>
                Admin
            </Anchor>
            {workforce ? (
                <Anchor component={Link} to={"/admin/workforces"}>
                    Effectifs
                </Anchor>
            ) : (
                <span>Effectifs</span>
            )}
            {workforce === "new" && (
                <span>Nouvel effectif</span>
            )}
        </Breadcrumbs>
    );
}

export default WorkforceBreadcrumbs;
