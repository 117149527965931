import { UseFormReturnType } from "@mantine/form";
import { ColorInput, Group } from "@mantine/core";

export function ColorsFormInput<
  T extends { colors: Record<string, string> }
>({
  form,
  fields,
}: {
  form: UseFormReturnType<T>;
  fields: [keyof T["colors"], string][];
}) {
  return (
    <Group>
      {fields.map(([name, label]) => (
        <ColorInput
          key={name}
          required
          label={label}
          {...form.getInputProps(`colors.${String(name)}`)}
        />
      ))}
    </Group>
  );
}
