import { useEffect, useState } from "react"
import { Container } from "@mantine/core"
import { useInterval } from "@mantine/hooks"
import { AnimatePresence } from "framer-motion"

import type { Message } from "wall/types/message.model"
import { wallTemplate } from "wall/types/wall.model"
import { default as CoubertinAnimatedMessage } from "./coubertin/AnimatedMessage"
import { default as ParcDesPrincesAnimatedMessage } from "./parc_des_princes/AnimatedMessage"
import { WallContext, useWall } from "wall/hooks/useWall"

const MessagesSlideshow = ({
  messages,
  template,
}: {
  messages: Message[]
  template: wallTemplate
}) => {
  const wall = useWall()
  const [index, setIndex] = useState(0)

  const interval = useInterval(() => {
    setIndex((index) => (index + 1) % (messages?.length ?? 1))
  }, 5000)
  const { start: startInterval, stop: stopInterval } = interval

  useEffect(() => {
    if (messages) {
      startInterval()
      return stopInterval
    }
  }, [messages, startInterval, stopInterval])

  const AnimatedMessage =
    template === "bandeau_horizontal"
      ? CoubertinAnimatedMessage
      : ParcDesPrincesAnimatedMessage

  return (
    <Container size={template === "bandeau_horizontal" ? "xl" : undefined}>
      <AnimatePresence mode="wait">
        {wall ? (
          <WallContext.Provider value={wall}>
            <AnimatedMessage key={index} message={messages[index]} />
          </WallContext.Provider>
        ) : null}
      </AnimatePresence>
    </Container>
  )
}

export default MessagesSlideshow
