import { Box, MantineProvider } from "@mantine/core"

export function ScreenLayout({
  theme,
  children,
}: {
  theme: {
    colors: { background: string; text?: string; questionText?: string }
    images: { screenBackground: string }
    screenType: string
  }
  children: React.ReactNode
}) {
  const backgroundSize =
    theme.screenType === "bandeau_horizontal" ? "1540px 220px" : "1920px 1080px"
  return (
    <Box
      className={
        "h-screen w-screen overflow-hidden bg-cover bg-no-repeat flex justify-center items-center"
      }
      sx={{
        background: theme.colors.background,
        color: theme.colors.text ?? theme.colors.questionText,
        fontFamily: "QuizCustomFont, sans-serif",
        backgroundImage: `url(${theme.images.screenBackground})`,
        backgroundSize,
      }}
    >
      <MantineProvider theme={{ fontFamily: "QuizCustomFont, sans-serif" }}>
        {children}
      </MantineProvider>
    </Box>
  )
}
