import { motion } from "framer-motion"
import { Box, Group, Text } from "@mantine/core"

import type { MultipleChoiceQuestion, Quiz } from "quiz/types/quiz.model"

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

const ParcDesPrincesChoices = ({
  question,
  quiz,
}: {
  question: MultipleChoiceQuestion
  quiz: Omit<Quiz, "id">
}) => {
  return (
    <motion.div
      style={{ flexGrow: 1, flexShrink: 1 }}
      variants={{
        choices: {
          transition: {
            type: "spring",
            bounce: 0,
            when: "beforeChildren",
            staggerChildren: 0.1,
          },
        },
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        {question.choices.map((choice, idx) => (
          <motion.div
            key={idx}
            variants={{
              initial: { translateY: "100vh", opacity: 0 },
              choices: {
                transition: { type: "spring", bounce: 0 },
                translateY: 0,
                opacity: 1,
              },
              solution: (isCorrect) => ({
                opacity: isCorrect ? 1 : 0.3,
                transition: { type: "spring", bounce: 0 },
              }),
            }}
            custom={question.solutionIdx === idx}
          >
            <Box
              sx={{
                background: quiz.colors.choiceBackground,
                color: quiz.colors.choiceText,
                padding: "15px 20px",
                borderRadius: "60px",
                fontSize: "56px",
                lineHeight: "64px",
              }}
            >
              <Group>
                <Text
                  component="span"
                  color={quiz.colors.choiceNumber}
                  inherit
                  sx={{
                    borderRadius: "40px",
                    background: "black",
                    padding: "5px 25px",
                    translateY: -3,
                  }}
                >
                  {letters[idx]}
                </Text>
                {question.choices[idx].label}
              </Group>
            </Box>
          </motion.div>
        ))}
      </Box>
    </motion.div>
  )
}

export { ParcDesPrincesChoices }
