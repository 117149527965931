import { useContext, useMemo } from "react"
import { isEmpty } from "lodash"
import { motion } from "framer-motion"
import { Text } from "@mantine/core"

import type { Message } from "wall/types/message.model"
import { config } from "config"
import { getImageUrl } from "common/utils/getImageUrl"
import { WallContext } from "wall/hooks/useWall"

function AnimatedMessage({ message }: { message: Message }) {
  const wall = useContext(WallContext)

  const styles = useMemo(
    () =>
      !isEmpty(wall) && wall.colors
        ? {
            message: {
              color: wall.colors.messages,
              fontSize: wall.msgFontSize,
            },
            author: {
              color: wall.colors.authors,
              fontSize: wall.msgAuthorFontSize,
            },
          }
        : {
            message: { color: "#ffffff", fontSize: "60px" },
            author: { color: "#ffffff", fontSize: "40px" },
          },
    [wall]
  )
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className="flex gap-3 items-center"
    >
      {message.imageKey && (
        <img
          className="max-h-[80vh] max-w-[50vw] object-contain rounded-2xl"
          src={getImageUrl(message.imageKey)}
          alt="Non trouvée"
        />
      )}
      <div className={"p-10"}>
        <Text className={`leading-[45px] mb-3`} sx={styles.message}>
          {message.message.substring(0, config.walls.maxMessageSize)}
        </Text>
        <Text className={`text-right font-bold `} sx={styles.author}>
          {message.author}
        </Text>
      </div>
    </motion.div>
  )
}

export default AnimatedMessage
