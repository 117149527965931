import { PartialDeep } from "type-fest";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export type Pluralize<T extends string> = T extends "Quiz"
  ? "Quizzes"
  : `${T}s`;

const pluralize = <T extends string>(str: T): Pluralize<T> => {
  return (str === "Quiz" ? "Quizzes" : str + "s") as Pluralize<T>;
};

export const makeEndpoint = <K extends string, T>(name: K, value: T) => {
  return { [name]: value } as Record<K, T>;
};

export const makeCrudEndpoints =
  <N extends string, T>(entityName: N, restResourcePath: string) =>
  (build: EndpointBuilder<any, any, any>) => {
    return {
      ...makeEndpoint(
        `add${entityName}`,
        build.mutation<T, PartialDeep<T>>({
          query: (body) => ({ method: "POST", url: restResourcePath, body }),
          invalidatesTags: [`${entityName}s`],
        })
      ),
      ...makeEndpoint(
        `list${pluralize(entityName)}`,
        build.query<T[], void | Record<string, string>>({
          query: (filters) => ({
            method: "GET",
            url:
              restResourcePath +
              (filters
                ? "?" +
                  Object.entries(filters)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")
                : ""),
          }),
          providesTags: [`${entityName}s`],
        })
      ),
      ...makeEndpoint(
        `get${entityName}`,
        build.query<T, string>({
          query: (id) => ({
            method: "GET",
            url: `${restResourcePath}/${id}`,
          }),
          providesTags: (result, error, id) => [{ type: entityName, id }],
        })
      ),
      ...makeEndpoint(
        `remove${entityName}`,
        build.mutation<void, string>({
          query: (id) => ({
            method: "DELETE",
            url: `${restResourcePath}/${id}`,
          }),
          invalidatesTags: (result, error, id) => [
            `${entityName}s`,
            { type: entityName, id },
          ],
        })
      ),
      ...makeEndpoint(
        `update${entityName}`,
        build.mutation<T, Partial<T> & { id: string }>({
          query: ({ id, ...body }) => ({
            method: "POST",
            url: `${restResourcePath}/${id}`,
            body,
          }),
          invalidatesTags: (result, error, { id }) => [
            `${entityName}s`,
            { type: entityName, id },
          ],
        })
      ),
    } as const;
  };

export function throwError<T>(
  resp: { data: T } | { error: FetchBaseQueryError | SerializedError }
) {
  if ("data" in resp) {
    return resp.data;
  } else {
    console.error(resp.error);
    throw new Error("API call failed");
  }
}
