const ADMIN_ROLE = "Admin"
const ADMIN_ORGANIZATION = "Admin"

const ORGANIZATIONS = [
  { value: "136", label: "136" },
  { value: "Lille", label: "Lille" },
  { value: "Nanterre", label: "Nanterre" },
  { value: "PSG", label: "PSG" },
  { value: "Reims", label: "Reims" },
]

const CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION, "136", "PSG"]
const CLICK_AND_COLLECT_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
const CLICK_AND_COLLECT_CREATE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "PSG",
]
const CLICK_AND_COLLECT_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
const CLICK_AND_COLLECT_DELETE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const CLICK_AND_COLLECT_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]
const CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
const CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]
const CLICK_AND_COLLECT_UPDATE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "PSG",
]
const CLICK_AND_COLLECT_UPDATE_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]

const DIAPORAMA_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "Lille",
  "Nanterre",
  "PSG",
  "Reims",
]
const DIAPORAMA_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
const DIAPORAMA_CREATE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const DIAPORAMA_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE]
const DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]
const DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

const ENTITY_DELETE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const ENTITY_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]

const EVENT_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const EVENT_AUTHORISED_ROLES = [ADMIN_ROLE]
const EVENT_CREATE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const EVENT_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE]
const EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

const QUIZ_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "Lille",
  "Nanterre",
  "PSG",
  "Reims",
]
const QUIZ_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
const QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

const WORKFORCE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
const WORKFORCE_AUTHORISED_ROLES = [ADMIN_ROLE]

export {
  ADMIN_ROLE,
  ADMIN_ORGANIZATION,
  ORGANIZATIONS,
  CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_AUTHORISED_ROLES,
  CLICK_AND_COLLECT_CREATE_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_CREATE_AUTHORISED_ROLES,
  CLICK_AND_COLLECT_DELETE_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_DELETE_AUTHORISED_ROLES,
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  CLICK_AND_COLLECT_UPDATE_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_UPDATE_AUTHORISED_ROLES,
  DIAPORAMA_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_AUTHORISED_ROLES,
  DIAPORAMA_CREATE_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_CREATE_AUTHORISED_ROLES,
  DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ROLES,
  DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  ENTITY_DELETE_AUTHORISED_ORGANIZATIONS,
  ENTITY_DELETE_AUTHORISED_ROLES,
  EVENT_AUTHORISED_ORGANIZATIONS,
  EVENT_AUTHORISED_ROLES,
  EVENT_CREATE_AUTHORISED_ORGANIZATIONS,
  EVENT_CREATE_AUTHORISED_ROLES,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  QUIZ_AUTHORISED_ORGANIZATIONS,
  QUIZ_AUTHORISED_ROLES,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  WORKFORCE_AUTHORISED_ORGANIZATIONS,
  WORKFORCE_AUTHORISED_ROLES,
}
