import { Group } from "@mantine/core"
import { CircleCheck, CircleDashed, CircleDot } from "tabler-icons-react"

import type { Game } from "quiz/types/game.model"

export function QuizGameStateLabel({ game }: { game: Game }) {
  return {
    CREATED: (
      <Group>
        <CircleDashed />
        Non démarrée
      </Group>
    ),
    GAME_OVER: (
      <Group>
        <CircleDot />
        En cours (calcul des scores)
      </Group>
    ),
    NEXT: (
      <Group>
        <CircleDot />
        En cours (question suivante)
      </Group>
    ),
    QUESTION: (
      <Group>
        <CircleDot />
        En cours ({(game.state?.questionIdx || 0) + 1}/
        {game.quizSnapshot.questions.length})
      </Group>
    ),
    RESULTS: (
      <Group>
        <CircleCheck />
        Terminée
      </Group>
    ),
    undefined: null,
  }[game.state?.step]
}
