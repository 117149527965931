export const openWindow = (
  url: string,
  width?: number,
  height?: number,
  target?: string
) => {
  const features =
    width && height
      ? `menubar=0,resizable=1,width=${width},height=${height}`
      : `menubar=0,resizable=1`
  window.open(
    url,
    target ?? `window${Math.random()}`,
    features
  )
}
