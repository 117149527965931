import { motion } from "framer-motion"
import { Box, Group, Text } from "@mantine/core"

import type { MultipleChoiceQuestion, Quiz } from "quiz/types/quiz.model"

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

const CourbertinChoices = ({
  question,
  quiz,
}: {
  question: MultipleChoiceQuestion
  quiz: Omit<Quiz, "id">
}) => {
  return (
    <motion.div
      style={{ height: "100%", flexGrow: 0, flexShrink: 1 }}
      variants={{
        initial: {
          flexBasis: "0%",
        },
        choices: {
          flexBasis: "30%",

          transition: {
            type: "spring",
            bounce: 0,
            when: "beforeChildren",
            staggerChildren: 0.1,
          },
        },
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        {question.choices.map((choice, idx) => (
          <motion.div
            key={idx}
            variants={{
              initial: { translateY: "100vh", opacity: 0 },
              choices: {
                transition: { type: "spring", bounce: 0 },
                translateY: 0,
                opacity: 1,
              },
              solution: (isCorrect) => ({
                opacity: isCorrect ? 1 : 0.3,
                transition: { type: "spring", bounce: 0 },
              }),
            }}
            custom={question.solutionIdx === idx}
          >
            <Box
              sx={{
                background: quiz.colors.choiceBackground,
                color: quiz.colors.choiceText,
                padding: "0px 20px 0px 20px",
                borderRadius:
                  question.choices[idx].label.length > 44 ? "20px" : "30px",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "12px",
              }}
            >
              <Group className="flex">
                <Text className="max-w-4">
                  <Text
                    className="mr-2"
                    component="span"
                    color={quiz.colors.choiceNumber}
                    inherit
                  >
                    {letters[idx]}.
                  </Text>
                  {question.choices[idx].label}
                </Text>
              </Group>
            </Box>
          </motion.div>
        ))}
      </Box>
    </motion.div>
  )
}

export { CourbertinChoices }
