import { Outlet } from "react-router-dom"
import { AppShell, Box, Container } from "@mantine/core"

import { Header } from "../admin/components/Header"
import { withDebug } from "common/debug/components/withDebug"

function AdminLayoutComponent() {
  return (
    <AppShell padding={0} header={<Header />}>
      <Box
        sx={(theme) => ({
          background:
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2],
          minHeight: "calc(100vh - 64px)",
        })}
        p={"lg"}
      >
        <Container className="min-h-full">
          <Outlet />
        </Container>
      </Box>
    </AppShell>
  )
}

export const AdminLayout = withDebug(AdminLayoutComponent)