import { NavLink } from "react-router-dom"
import { DoorExit, Globe } from "tabler-icons-react"
import { ActionIcon, Group, Title } from "@mantine/core"

import { useQuizToken } from "common/admin/auth/hooks"

export function Header() {
  const [, setQuizToken] = useQuizToken()

  return (
    <Group
      sx={(theme) => ({
        background:
          theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        borderBottom:
          "1px solid " +
          (theme.colorScheme === "light" ? "#e9ecef" : theme.colors.gray[7]),
      })}
      position={"apart"}
    >
      <NavLink to={"/admin/"} className="no-underline">
        <Title
          order={3}
          p="md"
          sx={(theme) => ({
            color: theme.colorScheme === "dark" ? theme.white : theme.black,
          })}
        >
          <Group spacing={5}>
            <Globe />
            LiveQuiz
          </Group>
        </Title>
      </NavLink>
      <ActionIcon variant="default" m={"md"} onClick={() => setQuizToken(null)}>
        <DoorExit size={16} />
      </ActionIcon>
    </Group>
  )
}
