import { useState, useMemo, useEffect, useContext } from "react"
import { Flex, Stack, Textarea } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { config } from "config"
import type { Message } from "wall/types/message.model"
import { WallContext } from "wall/hooks/useWall"

type MessageTextAreaInputProps = {
  form: UseFormReturnType<Partial<Message>>
  inputValueName: string
}

const MessageTextAreaInput = ({
  form,
  inputValueName,
}: MessageTextAreaInputProps) => {
  const wall = useContext(WallContext)
  const [charCount, setCharCount] = useState(0)
  const maxChar = config.walls.maxMessageSize

  const isReadOnly = useMemo(
    () => charCount === maxChar + 1,
    [charCount, maxChar]
  )

  useEffect(() => {
    const messageValue = form.getInputProps(inputValueName).value
    const countChar = messageValue.length
    if (countChar > maxChar) {
      form.setFieldValue(
        inputValueName,
        messageValue.toString().substring(0, maxChar)
      )
      return
    }
    setCharCount(countChar)
  }, [form, form.values, inputValueName, maxChar])

  return (
    <Stack>
      <Textarea
        key={inputValueName}
        label={wall.labelFormMessage ? wall.labelFormMessage : "Message"}
        maxLength={maxChar}
        minRows={"6"}
        readOnly={isReadOnly}
        required
        radius={"xs"}
        styles={{
          root: { borderRadius: 0 },
          label: {
            color: wall?.colors?.labels,
            fontFamily: "Team-A-Bold",
            fontSize: "1rem",
            fontWeight: "bold",
            span: "blue",
            textTransform: "uppercase",
          },
          input: {
            fontFamily: "Team-A-Regular",
            fontWeight: "normal",
          },
          required: { color: wall?.colors?.mandatory },
        }}
        {...form.getInputProps(inputValueName)}
      />
      <Flex
        className="bg-white"
        justify="flex-end"
        align="center"
        direction="row"
        style={{ position: "relative", top: "-17px", padding: "5px" }}
      >
        <span
          style={{ color: wall?.colors?.mandatory }}
        >{`${charCount}/${maxChar}`}</span>
      </Flex>
    </Stack>
  )
}

export { MessageTextAreaInput }
