import { TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { PlayerFormFields } from "common/form/PlayerTypes"

type FormFieldTextInputProps<T> = {
  form: UseFormReturnType<T, (values: T) => T>
  fontColor: string
  label: string
  name: string
  isRequired: boolean
  type: string
}

const FormFieldTextInput = ({
  form,
  fontColor,
  label,
  name,
  isRequired,
  type,
}: FormFieldTextInputProps<Partial<PlayerFormFields>>) => (
  <TextInput
    classNames={{
      root: "max-w-full ",
    }}
    key={name}
    label={label}
    name={name}
    size={"md"}
    styles={{
      label: {
        fontFamily: "Team-A-Bold",
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: `${fontColor}`,
        span: "blue",
      },
      input: {
        fontFamily: "Team-A-Regular",
        fontWeight: "normal",
      },
    }}
    radius={"xs"}
    required={isRequired}
    type={type}
    {...form.getInputProps(name)}
  />
)

export { FormFieldTextInput }
