import { Provider } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { MantineProvider } from "@mantine/core"
import { ModalsProvider } from "@mantine/modals"

import { store } from "core/store"

import { AdminLayout } from "common/layouts/AdminLayout"
import { AdminMenuView } from "common/admin/AdminMenuView"
import { AdminStagingHome } from "pages/home/AdminStagingHome"

import { DebugProvider } from "common/debug/contexts/DebugContext"

import Login from "common/admin/auth/Login"
import RequireAuth from "common/admin/auth/RequireAuth"

import { ClickAndCollectAdminRoutes } from "click-and-collect/routes/ClickAndConnectAdminRoutes"

import { EventAdminRoutes } from "event/routes/EventAdminRoutes"
import { EventPlayRoutes } from "event/routes/EventPlayRoutes"

import { WorkforceAdminRoutes } from "workforce/admin/WorkforceAdminRoutes"
import { PronosticsAdminRoutes } from "pronostic/routes/PronosticsAdminRoutes"

import { QuizAdminRoutes } from "quiz/routes/QuizAdminRoutes"
import { QuizScreencastRoutes } from "quiz/routes/QuizScreencastRoutes"
import { QuizGamePlayRoutes } from "quiz/routes/QuizGamePlayRoutes"

import { WallAdminRoutes } from "wall/routes/WallAdminRoutes"
import { WallScreenRoutes } from "wall/routes/WallScreenRoutes"
import { WallPlayRoutes } from "wall/routes/WallPlayRoutes"

import "./App.css"

function App() {
  return (
    <DebugProvider>
      <Provider store={store}>
        <MantineProvider
          theme={{ primaryColor: "violet", colorScheme: "light" }}
          withGlobalStyles
          withNormalizeCSS
        >
          <ModalsProvider modalProps={{ style: { overflow: "hidden" } }}>
            <Routes>
              {process.env.REACT_APP_ENV === "staging" && (
                <Route path="" element={<AdminStagingHome />} />
              )}

              <Route path="admin">
                <Route path="login" element={<Login />} />
                <Route element={<RequireAuth />}>
                  <Route element={<AdminLayout />}>
                    <Route path="" element={<AdminMenuView />} />
                    <Route
                      path="clickandcollect/*"
                      element={<ClickAndCollectAdminRoutes />}
                    />

                    <Route path="events/*" element={<EventAdminRoutes />} />
                    <Route
                      path="pronostics/*"
                      element={<PronosticsAdminRoutes />}
                    />
                    <Route path="quizzes/*" element={<QuizAdminRoutes />} />
                    <Route path="walls/*" element={<WallAdminRoutes />} />
                    <Route
                      path="workforces/*"
                      element={<WorkforceAdminRoutes />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="event/*" element={<EventPlayRoutes />} />

              <Route path="quiz/*" element={<QuizGamePlayRoutes />} />

              <Route path="wall/*" element={<WallPlayRoutes />} />

              <Route path="screen">
                <Route path="game/*" element={<QuizScreencastRoutes />} />
                <Route path="wall/*" element={<WallScreenRoutes />} />
              </Route>
            </Routes>
          </ModalsProvider>
        </MantineProvider>
      </Provider>
    </DebugProvider>
  )
}

export default App
