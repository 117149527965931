import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Box, Button, Group, Stack, Table, Title } from "@mantine/core"
import { v4 as uuidv4 } from "uuid"

import {
  ADMIN_ORGANIZATION,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"

import type { Quiz } from "quiz/types/quiz.model"
import { AccessRights } from "common/admin/auth/AccessRights"
import Collapsable from "common/admin/ui/Collapsable"
import { useOrganization } from "common/admin/auth/hooks"
import QueryWrapper from "common/components/QueryWrapper"
import { Th } from "common/components/table/Th"

import { useListQuizzesQuery } from "quiz/api/quizzes.endpoints"
import { QuizBreadcrumbs } from "quiz/admin/components/QuizBreadcrumbs"
import { QuizzesListTableRow } from "quiz/admin/components/QuizzesListTableRow"

type SortedKeys =
  | keyof Pick<
      Quiz,
      | "creationTime"
      | "entity"
      | "name"
      | "organization"
      | "screenType"
      | "section"
    >

function sortData(
  data: Quiz[],
  payload: {
    sortBy: SortedKeys
    reversed: boolean
  }
): Quiz[] {
  let { sortBy } = payload

  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[sortBy].localeCompare(a[sortBy])
    }
    return a[sortBy].localeCompare(b[sortBy])
  })
}

export function QuizzesListAdminView() {
  const navigate = useNavigate()
  const listQuizzesQuery = useListQuizzesQuery()
  const [sortedData, setSortedData] = useState<Quiz[]>([])
  const [sortBy, setSortBy] = useState<SortedKeys>("creationTime")
  const [reverseSortDirection, setReverseSortDirection] = useState(false)
  const [organization] = useOrganization()

  const quizzes = useMemo(() => listQuizzesQuery.data, [listQuizzesQuery.data])

  const triggerSortingData = useCallback(
    (field: SortedKeys) => {
      if (quizzes) {
        const reversed = field === sortBy ? !reverseSortDirection : false
        return sortData(quizzes, { sortBy, reversed })
      }
      return []
    },
    [quizzes, reverseSortDirection, sortBy]
  )

  useEffect(() => {
    if (quizzes) {
      setSortedData(triggerSortingData(sortBy))
    }
  }, [quizzes, sortBy, triggerSortingData])

  return (
    <Box>
      <QuizBreadcrumbs />

      <Title order={3} align={"center"} m={"lg"}>
        Liste des quizzes
      </Title>

      <Group position={"right"}>
        <Button className="bg-button-admin" component={Link} to="new">
          Nouveau quiz
        </Button>
      </Group>

      <Collapsable title={"Liste des quizzes"}>
        <QueryWrapper query={listQuizzesQuery}>
          {quizzes ? (
            <Table highlightOnHover>
              <thead>
                <tr>
                  <Th
                    reversed={reverseSortDirection}
                    sorted={sortBy === "name"}
                    onClick={() => {
                      if (sortBy === "name") {
                        setReverseSortDirection(!reverseSortDirection)
                      } else {
                        setSortBy("name")
                      }
                    }}
                  >
                    Nom
                  </Th>
                  <AccessRights
                    authorizedRoles={
                      QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
                    }
                    authorizedOrganizations={
                      QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
                    }
                  >
                    <Th
                      reversed={reverseSortDirection}
                      sorted={sortBy === "organization"}
                      onClick={() => {
                        if (sortBy === "organization") {
                          setReverseSortDirection(!reverseSortDirection)
                        } else {
                          setSortBy("organization")
                        }
                      }}
                    >
                      Organisme
                    </Th>
                  </AccessRights>
                  <th></th>
                  <Th
                    reversed={reverseSortDirection}
                    sorted={sortBy === "creationTime"}
                    onClick={() => {
                      if (sortBy === "creationTime") {
                        setReverseSortDirection(!reverseSortDirection)
                      } else {
                        setSortBy("creationTime")
                      }
                    }}
                  >
                    Date de création
                  </Th>
                </tr>
              </thead>
              <tbody>
                {sortedData.length > 0 &&
                  sortedData.map((quiz) =>
                    (quiz.organization && quiz.organization === organization) ||
                    organization === ADMIN_ORGANIZATION ? (
                      <QuizzesListTableRow
                        key={uuidv4()}
                        quiz={quiz}
                        onClick={() => {
                          navigate(quiz.id, { state: { quiz } })
                        }}
                      />
                    ) : null
                  )}
              </tbody>
            </Table>
          ) : (
            <Stack align={"center"}>
              <Box>Aucun quiz trouvé</Box>
              <Button className="bg-button-admin" component={Link} to="new">
                Nouveau quiz
              </Button>
            </Stack>
          )}
        </QueryWrapper>
      </Collapsable>
    </Box>
  )
}
