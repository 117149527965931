import { Anchor, Breadcrumbs } from "@mantine/core"
import { Link } from "react-router-dom"
import type { Wall } from "../../types/wall.model"

export function WallBreadcrumbs({ wall }: { wall?: Wall }) {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>
      {wall ? (
        <Anchor component={Link} to="/admin/walls">
          Diaporamas
        </Anchor>
      ) : (
        <span>Diaporamas</span>
      )}
      {wall && <span>{wall!.name || `Wall #${wall!.id.split("-")[0]}`}</span>}
    </Breadcrumbs>
  )
}
