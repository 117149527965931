import { useContext, useState } from "react"
import { Button, Center, FileInput, Image, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { Upload } from "tabler-icons-react"

import type { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import type { MutationDefinition } from "@reduxjs/toolkit/dist/query"
import type { Message } from "wall/types/message.model"

import MutationWrapper from "common/components/MutationWrapper"
import {
  useGetPresignedPostMutation,
  useUploadToS3Mutation,
} from "common/upload/api/upload.enpoints"
import { MessageTextAreaInput } from "wall/play/components/MessageTextAreaInput"
import { PseudoInput } from "wall/play/components/PseudoInput"
import { WallContext } from "wall/hooks/useWall"

interface PostMessageFormProps {
  addMessage: MutationTrigger<
    MutationDefinition<Partial<Message>, any, any, Message, any>
  >
  addMessageResult: {
    data?: Message
    isLoading: boolean
    isError: boolean
  }
  form: UseFormReturnType<
    Partial<Message>,
    (values: Partial<Message>) => Partial<Message>
  >
  setIsMessageSent: React.Dispatch<React.SetStateAction<boolean>>
}

export function PostMessageForm({
  addMessage,
  addMessageResult,
  form,
  setIsMessageSent,
}: PostMessageFormProps) {
  const wall = useContext(WallContext)

  const [getPresignedPost, getPresignedPostResult] =
    useGetPresignedPostMutation()
  const [uploadToS3, uploadToS3Result] = useUploadToS3Mutation()

  const [file, setFile] = useState<File | null>(null)

  const submit = async () => {
    if (file) {
      const data = await getPresignedPost({
        fileName: file.name,
        folder: "wall-" + wall.id,
      }).unwrap()
      await uploadToS3({ file, data })
      addMessage({ ...form.values, imageKey: data.fields.key })
      setIsMessageSent(true)
    } else {
      addMessage(form.values)
      setIsMessageSent(true)
    }
  }

  return (
    <form className="w-full max-w-480" onSubmit={form.onSubmit(submit)}>
      <MutationWrapper
        message="Impossible d'envoyer ton message pour l'instant. Réessaye plus tard !"
        title="Oups"
        variant="outline"
        result={addMessageResult}
      />
      <MutationWrapper
        message="Impossible d'envoyer ton message pour l'instant. Réessaye plus tard !"
        title="Oups"
        variant="outline"
        result={getPresignedPostResult}
      />
      <MutationWrapper
        message="Impossible d'envoyer ton message pour l'instant. Réessaye plus tard !"
        title="Oups"
        variant="outline"
        result={uploadToS3Result}
      />
      <Stack>
        <Center>
          <Image
            width={75}
            src={wall?.images?.formLogo}
            style={{ paddingBottom: "10px" }}
            alt="Form Logo"
          />
        </Center>

        <PseudoInput
          form={form}
          inputValueName="author"
          maxChar={20}
          minChar={1}
        />

        {wall && wall.type === "IMAGES" ? null : (
          <MessageTextAreaInput form={form} inputValueName="message" />
        )}

        {wall && wall.type === "TEXT" ? null : (
          <FileInput /** Mantine core */
            label="Photo"
            styles={{
              label: {
                fontFamily: "Team-A-Bold",
                fontSize: "1rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: wall?.colors?.labels,
                span: "blue",
              },
              input: {
                color: wall?.colors?.upload,
                fontFamily: "Team-A-Regular",
                fontWeight: "normal",
              },
              placeholder: {
                color: wall?.colors?.upload,
                fontWeight: "normal",
              },
              required: { color: wall?.colors?.mandatory },
            }}
            radius={"xs"}
            icon={<Upload size={14} />}
            placeholder="AJOUTEZ VOTRE PHOTO"
            required={wall && wall.type === "IMAGES"}
            value={file}
            onChange={setFile}
          />
        )}
        <div>
          <Text
            className={`text-center text-base font-team-a-bold my-1.5 mx-0 leading-5`}
            sx={{ color: wall?.colors?.info }}
          >
            {wall?.welcomeMsg}
          </Text>
        </div>
        <div className="text-center">
          <Button
            type="submit"
            radius={"xs"}
            styles={{
              root: {
                borderWidth: "2px",
                borderColor: wall?.colors?.buttonBorder,
                color: wall?.colors?.buttonText,
                padding: 0,
                width: "8rem",
              },
              inner: {
                backgroundColor: wall?.colors?.buttonBackground,
                fontSize: "1rem",
                textTransform: "uppercase",
                width: "100%",
              },
            }}
          >
            <span>Envoyer</span>
          </Button>
        </div>
      </Stack>
    </form>
  )
}
