import { Routes, Route } from "react-router-dom"
import { ClickAndCollectAdminList } from "click-and-collect/components/ClickAndCollectAdminList"

export function ClickAndCollectAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<ClickAndCollectAdminList />} />
    </Routes>
  )
}
