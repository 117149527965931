import { Popover } from "@mantine/core"
import { Photo } from "tabler-icons-react"
import { useDisclosure } from "@mantine/hooks"
import { openModal } from "@mantine/modals"
import { getImageUrl } from "common/utils/getImageUrl"

function MessageImage({
  imageKey,
  imageKeyApproved,
}: {
  imageKey: string
  imageKeyApproved?: boolean
}) {
  const [opened, { close, open }] = useDisclosure(false)
  const openImageOpen = () =>
    openModal({
      children: (
        <div>
          <img src={getImageUrl(imageKey)} alt="Non trouvée" />
        </div>
      ),
      size: "auto",
      withCloseButton: false,
    })

  return (
    <Popover opened={opened} withArrow shadow="md" position="bottom">
      <Popover.Target>
        <div className="inline">
          <Photo
            color={imageKeyApproved === false ? "red" : undefined}
            size={20}
            onMouseEnter={open}
            onMouseLeave={close}
            onClick={openImageOpen}
            className="cursor-pointer"
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          pointerEvents: "none",
          maxWidth: "35vw",
          maxHeight: "35vh",
          width: "auto",
          height: "auto",
        }}
      >
        <img
          src={getImageUrl(imageKey)}
          alt="Non trouvée"
          style={{
            maxWidth: "calc(35vw - 32px)",
            maxHeight: "calc(35vh - 32px)",
          }}
        />
      </Popover.Dropdown>
    </Popover>
  )
}

export default MessageImage
