import { useCallback, useEffect, useState } from "react"
import { Select, TextInput, ActionIcon, Anchor, Loader } from "@mantine/core"
import { useForm } from "@mantine/form"
import { zodResolver } from "mantine-form-zod-resolver"
import { Check, Edit, Trash, X } from "tabler-icons-react"
import { z } from "zod"

import {
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  ORGANIZATIONS,
} from "_roles"
import type {
  FoodMenu,
  FoodMenuFormMode,
} from "click-and-collect/types/foodMenu.model"
import FileInput from "common/admin/ui/FileInput"

import {
  useAddFoodMenuMutation,
  useRemoveFoodMenuMutation,
  useUpdateFoodMenuMutation,
} from "click-and-collect/api/foodMenu.api"
import { AccessRights } from "common/admin/auth/AccessRights"
interface FoodMenuFormProps {
  foodMenu?: FoodMenu
  initialMode: FoodMenuFormMode
  organization: string
  setReadOnly: () => void
  refetch: () => void
}

export function FoodMenuForm({
  foodMenu,
  initialMode,
  organization,
  setReadOnly,
  refetch,
}: FoodMenuFormProps) {
  const [localMode, setLocalMode] = useState<FoodMenuFormMode>(initialMode)

  const initialFormValues = useCallback(() => {
    return {
      document: foodMenu ? foodMenu.document : "",
      label: foodMenu ? foodMenu.label : "",
      organization: foodMenu ? foodMenu.organization : organization,
      creationTime: foodMenu
        ? new Date(foodMenu.creationTime).toISOString()
        : new Date().toISOString(),
    }
  }, [organization, foodMenu])

  const schema = z.object({
    document: z.string().trim().min(1, { message: "Document requis" }),
    label: z
      .string()
      .trim()
      .min(1, { message: "Intitulé requis" })
      .max(20, { message: "Intitulé limité à 20 caractères" }),
    organization: z.string().trim().min(1, { message: "Organisation requise" }),
  })

  const form = useForm({
    initialValues: initialFormValues(),
    validate: zodResolver(schema),
  })

  const [addFoodMenu, addFoodMenuResult] = useAddFoodMenuMutation()
  const [updateFoodMenu, updateFoodMenuResult] = useUpdateFoodMenuMutation()
  const [removeFoodMenu, removeFoodMenuResult] = useRemoveFoodMenuMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { isError: isErrorAddFoodMenuResult, error: errorAddFoodMenuResult } =
    addFoodMenuResult

  const {
    isError: isErrorUpdateFoodMenuResult,
    error: errorUpdateFoodMenuResult,
  } = updateFoodMenuResult

  const {
    isError: isErrorRemoveFoodMenuResult,
    error: errorRemoveFoodMenuResult,
  } = removeFoodMenuResult

  useEffect(() => {
    if (isErrorAddFoodMenuResult) {
      console.error("AddFoodMenuError", JSON.stringify(errorAddFoodMenuResult))
    }
    if (isErrorUpdateFoodMenuResult) {
      console.error(
        "UpdateFoodMenuError",
        JSON.stringify(errorUpdateFoodMenuResult)
      )
    }

    if (isErrorRemoveFoodMenuResult) {
      console.error(
        "RemoveFoodMenuError",
        JSON.stringify(errorRemoveFoodMenuResult)
      )
    }
  }, [
    isErrorAddFoodMenuResult,
    isErrorUpdateFoodMenuResult,
    isErrorRemoveFoodMenuResult,
    errorAddFoodMenuResult,
    errorUpdateFoodMenuResult,
    errorRemoveFoodMenuResult,
  ])

  const save = async () => {
    setIsLoading(true)
    if (foodMenu) {
      try {
        const updatePayload = updateFoodMenu({
          id: foodMenu.id,
          ...form.values,
        })
        console.log("updated", updatePayload)
      } catch (error) {
        console.error("update error", error)
      }
      return
    }
    try {
      const payload = await addFoodMenu(form.values).unwrap()
      console.log("addFoodMenu fulfilled", payload)
    } catch (error) {
      console.error("addFoodMenu rejected", error)
    }
    setIsLoading(false)
    setReadOnly()
  }

  if (foodMenu && initialMode === "read" && localMode === "read") {
    return (
      <tr>
        <td>{foodMenu.label}</td>
        <AccessRights
          authorizedRoles={
            CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
          }
          authorizedOrganizations={
            CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
          }
        >
          <td className="max-w-2">{foodMenu.organization}</td>
        </AccessRights>
        <td>
          {new Date(Date.parse(foodMenu.creationTime)).toLocaleString(
            undefined,
            {
              dateStyle: "short",
              timeStyle: "short",
            }
          )}
        </td>
        <td className="max-w-60">
          <Anchor href={foodMenu.document} target="_blank">
            {foodMenu.document}
          </Anchor>
        </td>
        <td className="min-w-4">
          <div className="flex">
            <ActionIcon onClick={() => setLocalMode("edit")}>
              <Edit color="blue" size={20} />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                removeFoodMenu(foodMenu.id)
                refetch()
              }}
            >
              <Trash color="red" size={20} />
            </ActionIcon>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td>
        <TextInput
          classNames={{
            root: "max-w-full ",
          }}
          key={"label"}
          name={"label"}
          size={"sm"}
          styles={{
            label: {
              fontFamily: "Team-A-Bold",
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              span: "blue",
            },
            input: {
              borderRadius: "5px",
              fontFamily: "Team-A-Regular",
              fontWeight: "normal",
            },
          }}
          radius={"xs"}
          type={"string"}
          {...form.getInputProps("label")}
        />
      </td>
      <AccessRights
        authorizedRoles={
          CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
        }
        authorizedOrganizations={
          CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
      >
        <td className="max-w-2">
          <Select
            sx={{ flex: 1 }}
            data={ORGANIZATIONS}
            {...form.getInputProps("organization")}
          />
        </td>
      </AccessRights>
      <td>
        {new Date().toLocaleString(undefined, {
          dateStyle: "short",
          timeStyle: "short",
        })}
      </td>
      <td className="max-w-60">
        <FileInput isPDF={true} {...form.getInputProps("document")} />
      </td>
      <td>
        <div className="flex">
          {isLoading ? (
            <Loader size={20} />
          ) : (
            <>
              <ActionIcon
                onClick={() => {
                  if (form.validate().hasErrors) {
                    console.error(
                      "Form validation errors",
                      JSON.stringify(form.validate().errors)
                    )
                    return
                  }
                  save()
                }}
              >
                <Check color="green" size={20} />
              </ActionIcon>
              <ActionIcon
                onClick={() => {
                  setLocalMode("read")
                  setReadOnly()
                }}
              >
                <X color="red" size={20} />
              </ActionIcon>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
