import React, { useState } from "react";
import { Button, Group, LoadingOverlay } from "@mantine/core";
import { useModals } from "@mantine/modals";

export function RemoveEntityModal({
  modalId,
  remove,
  onRemoved,
}: {
  modalId: string;
  remove: () => Promise<unknown>;
  onRemoved: () => unknown;
}) {
  const [removing, setRemoving] = useState(false);

  const ctx = useModals();

  const handleConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setRemoving(true);
    await remove();
    ctx.closeModal(modalId);
    onRemoved();
  };

  return (
    <>
      <LoadingOverlay visible={removing} />

      <Group position="right">
        <Button variant="default" onClick={() => ctx.closeModal(modalId)}>
          Annuler
        </Button>

        <Button className="bg-warning" onClick={handleConfirm}>
          Supprimer
        </Button>
      </Group>
    </>
  );
}
