import { Container } from "@mantine/core"

import { EVENT_LANDING_MSG } from "_constants"
import { EventHomepageHeader } from "event/play/components/EventHomepageHeader"
import type { Event } from "event/types/event.model"

import { PSGListNavigation } from "event/play/components/PSGListNavigation"
import "./RegisteredEventHomepageView.css"

type RegisteredEventHomepageViewProps = {
  event: Event
}

const RegisteredEventHomepageView = ({
  event,
}: RegisteredEventHomepageViewProps) => {
  return (
    <Container className="event-screens">
      <EventHomepageHeader />

      <PSGListNavigation event={event} open={true} />

      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "Team-A-Regular",
          paddingBottom: "20px",
        }}
        dangerouslySetInnerHTML={{
          __html: event.labels?.registered_welcome_msg
            ? event.labels.registered_welcome_msg
            : EVENT_LANDING_MSG,
        }}
      />
    </Container>
  )
}

export { RegisteredEventHomepageView }
