import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "mantine-form-zod-resolver"
import { Button, Modal, Stack, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"

import { gameService } from "services/services"
import type { Game } from "quiz/types/game.model"
import type { Quiz } from "quiz/types/quiz.model"
import { useOrganization, useEntity, useSection } from "common/admin/auth/hooks"

export function CreateGameModal({
  opened,
  onClose,
  quizSnapshot,
}: {
  opened: boolean
  onClose: () => void
  quizSnapshot: Quiz
}) {
  const [organization] = useOrganization()
  const [entity] = useEntity()
  const [section] = useSection()

  const schema = z.object({
    name: z.string().trim().min(1, { message: "Nom requis" }),
    state: z.object({ step: z.literal("CREATED") }),
    organization: z.string().trim().min(1, { message: "Organization requise" }),
    entity: z.string().trim().min(1, { message: "Entité requise" }),
    section: z.string().trim().min(1, { message: "Section requise" }),
  })

  const form = useForm({
    initialValues: {
      name: "",
      state: { step: "CREATED" },
      organization: "",
      entity: "",
      section: "",
    } as Omit<Game, "id">,
    validate: zodResolver(schema),
  })

  const [saving, setSaving] = useState(false)

  const { quizId } = useParams() as { quizId: string }
  const navigate = useNavigate()

  useEffect(() => {
    if (organization && organization !== "") {
      form.setFieldValue("organization", organization)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  useEffect(() => {
    if (entity && entity !== "") {
      form.setFieldValue("entity", entity)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  useEffect(() => {
    if (section && section !== "") {
      form.setFieldValue("section", section)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section])

  const submit = useCallback(
    (values: Omit<Game, "id">) => {
      setSaving(true)
      gameService
        .create({ ...values, quizId, quizSnapshot, mode: quizSnapshot.mode })
        .then((game) =>
          navigate(`./games/${game.id}`, {
            state: { game },
          })
        )
    },
    [navigate, quizId, quizSnapshot]
  )

  useEffect(() => {
    const today = new Date()
    const placeholderFormat = `${today.getFullYear()}${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${today.getDate().toString().padStart(2, "0")}`
    form.setFieldValue("name", `${placeholderFormat} - `)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal withCloseButton={false} opened={opened} onClose={onClose}>
      <form onSubmit={form.onSubmit(submit)}>
        <Stack>
          <TextInput
            label={"Nom de la partie"}
            {...form.getInputProps("name")}
          />
          <Button className="bg-button-admin" type={"submit"} disabled={saving}>
            Créer
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}
