import { useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Checkbox, Container, Stack, Text } from "@mantine/core"
import { useForm } from "@mantine/form"

import { config } from "config"
import { FormFieldTextInput } from "common/form/FormFieldTextInput"
import { throwError } from "common/api/apiHelpers"
import { useSavedEvent } from "event/hooks/useSavedEvent"
import { EventHomepageHeader } from "event/play/components/EventHomepageHeader"
import { useAddPlayerMutation } from "quiz/api/players.endpoints"
import type { Player } from "quiz/types/player.model"
import { PlayerFormFields } from "common/form/PlayerTypes"
import {
  FORM_VALIDATION_FIRST_NAME_REQUIRED,
  FORM_VALIDATION_LAST_NAME_REQUIRED,
  FORM_VALIDATION_EMAIL_NOT_VALIDATED,
  FORM_VALIDATION_PHONE_NOT_VALIDATED,
  FORM_VALIDATION_TERMS_OF_SERVICE,
  EVENT_CONTACT_FORM_WELCOME,
  EVENT_CONTACT_FORM_THANKS,
  EVENT_CONTACT_FORM_VALIDATION_BUTTON,
  EVENT_CONTACT_FORM_CHECKBOX_MESSAGE,
  EVENT_CONTACT_FORM_CHECKBOX_LINK,
} from "_constants"

import type { Event } from "event/types/event.model"

interface EventRegisterFormProps {
  event: Event
}

export function EventRegisterForm({ event }: EventRegisterFormProps) {
  const [checked, setChecked] = useState<boolean>(false)
  const [checkboxError, setCheckboxError] = useState<string | null>(null)
  const initialValues: Partial<PlayerFormFields> = {
    accessNumber: "", // not used here
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    rankNumber: "", // not used here
    seatNumber: "", // not used here
    termsOfService: checked,
  }
  const form = useForm({
    initialValues,
    validate: {
      firstName: (value: string) =>
        value ? null : FORM_VALIDATION_FIRST_NAME_REQUIRED,
      lastName: (value: string) =>
        value ? null : FORM_VALIDATION_LAST_NAME_REQUIRED,
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : FORM_VALIDATION_EMAIL_NOT_VALIDATED,
      phoneNumber: (value: string) =>
        value && value.length >= 5 && /^(\d|\+|-| )+$/.test(value)
          ? null
          : FORM_VALIDATION_PHONE_NOT_VALIDATED,
      termsOfService: (value: boolean) =>
        value === true ? false : FORM_VALIDATION_TERMS_OF_SERVICE,
    },
  })

  const { eventId } = useParams<{ eventId: string }>()
  const setSavedEvent = useSavedEvent(eventId!)[1]
  const [addPlayer] = useAddPlayerMutation()

  const on_submit = async (values: Partial<PlayerFormFields>) => {
    const playerInfo: Partial<PlayerFormFields> = {
      ...values,
      accessNumber: "",
      rankNumber: "",
      seatNumber: "",
      termsOfService: checked,
    }
    const newPlayer = await addPlayer(values as Partial<Player>).then(
      throwError
    )
    setSavedEvent(
      {
        playerId: newPlayer.id,
        partitionId: newPlayer.partitionId,
      },
      playerInfo
    )
  }

  const fontColor = "#FFFFFF"

  return (
    <Container
      className="min-h-screen flex flex-col items-center gap-x-16"
      pb={"lg"}
    >
      <EventHomepageHeader />
      <Stack>
        <Text
          className="text-xs text-center font-team-a-bold text-white"
          dangerouslySetInnerHTML={{
            __html: event.labels?.register_form_welcome_msg
              ? event.labels.register_form_welcome_msg
              : EVENT_CONTACT_FORM_WELCOME,
          }}
        />
      </Stack>
      <br />
      <form onSubmit={form.onSubmit(on_submit)}>
        <Stack className="w-screen px-2.5" px={"xl"} align={"center"}>
          <FormFieldTextInput
            label="Prénom"
            fontColor={fontColor}
            name="firstName"
            type="string"
            isRequired
            form={form}
          />
          <FormFieldTextInput
            label="Nom"
            fontColor={fontColor}
            name="lastName"
            type="string"
            isRequired
            form={form}
          />
          <FormFieldTextInput
            label="Email"
            fontColor={fontColor}
            name="email"
            type="string"
            isRequired
            form={form}
          />
          <FormFieldTextInput
            label="Téléphone"
            fontColor={fontColor}
            name="phoneNumber"
            type="string"
            isRequired
            form={form}
          />

          <Checkbox
            key="termsOfService"
            name="termsOfService"
            required
            checked={checked}
            classNames={{
              root: "w-90",
              label: "pl-0",
              labelWrapper: "ml-3",
            }}
            label={
              <Text
                className="font-team-a-bold leading-6 text-xs"
                style={{ color: `${fontColor}` }}
              >
                {event.labels?.register_form_checkbox_msg
                  ? event.labels?.register_form_checkbox_msg
                  : EVENT_CONTACT_FORM_CHECKBOX_MESSAGE}
                <br />
                <a
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                  href={config.legalUrl}
                >
                  {event.labels?.register_form_checkbox_link
                    ? event.labels?.register_form_checkbox_link
                    : EVENT_CONTACT_FORM_CHECKBOX_LINK}
                </a>
              </Text>
            }
            error={checkboxError}
            color={"red"}
            radius={"xl"}
            {...form.getInputProps("termsOfService", { type: "checkbox" })}
            onClick={(event) => {
              setCheckboxError(null)
              return setChecked(event.currentTarget.checked)
            }}
          />
          <div>
            <Text
              className="text-white text-center font-team-a-bold text-xs mx-0 my-px-5"
              dangerouslySetInnerHTML={{
                __html: event.labels?.register_form_thanks_msg
                  ? event.labels?.register_form_thanks_msg
                  : EVENT_CONTACT_FORM_THANKS,
              }}
            />
          </div>
          <div className="text-center">
            <Button
              type="submit"
              radius={"xs"}
              uppercase
              mx={"lg"}
              className="bg-button-play"
              styles={{
                root: {
                  minWidth: "125px",
                  backgroundColor: "#e30613",
                  borderColor: "white",
                  height: "32px",
                },
              }}
            >
              <span className="font-team-a-bold ">
                {event.labels?.register_form_validation_button
                  ? event.labels?.register_form_validation_button
                  : EVENT_CONTACT_FORM_VALIDATION_BUTTON}
              </span>
            </Button>
          </div>
        </Stack>
      </form>
    </Container>
  )
}
