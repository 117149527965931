import {
  Stack,
  TextInput,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";

import FileInput from "common/admin/ui/FileInput";
import { Workforce } from "./workforce.model";

export default function PlayerView(
  { playerIdx, form }:
  { playerIdx: number, form: UseFormReturnType<Omit<Workforce, "id">> }
) {
  return (
    <Stack>
      <TextInput
        label="Nom"
        required
        {...form.getInputProps(`players.${playerIdx}.name`)}
      />
      <FileInput
        label="Photo"
        {...form.getInputProps(`players.${playerIdx}.logo`)}
      />
    </Stack>
  );
}
