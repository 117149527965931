import { Route, Routes } from "react-router-dom"
import WorkforceView from "./WorkforceView"
import WorkforcesView from "./WorkforcesView"

export function WorkforceAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<WorkforcesView />} />
      <Route path="new" element={<WorkforceView />} />
      <Route path=":workforceId">
        <Route path="" element={<WorkforceView />} />
      </Route>
    </Routes>
  )
}
