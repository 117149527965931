import { Outlet } from "react-router-dom"
import { PlayLayout } from "common/layouts/PlayLayout"

const EventPlayThemeWrapper = () => {
  const defaultTheme = {
    colors: {
      background: "#131527",
      text: "#000000",
    },
    images: {
      playBackground: "/psg/background_mobile.png",
    },
  }

  return (
    <PlayLayout theme={defaultTheme}>
      <Outlet />
    </PlayLayout>
  )
}

export { EventPlayThemeWrapper }
