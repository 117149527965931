import { createFormContext } from "@mantine/form"
import type { Wall } from "wall/types/wall.model"
import { wallTemplate } from "wall/types/wall.model"

export const [WallFormProvider, useWallFormContext, useWallForm] =
  createFormContext<Omit<Wall, "id">>()

export const defaultWall: Omit<Wall, "id"> = {
  colors: {
    authors: "#ffffff",
    background: "#02184D",
    buttonBackground: "#e30613",
    buttonBorder: "#ffffff",
    buttonText: "#ffffff",
    info: "#ffffff",
    labels: "#ffffff",
    mandatory: "#e30613",
    messages: "#ffffff",
    upload: "#ffffff",
  },
  entity: "",
  images: {
    screenBackground: "/psg/bg.png",
    playBackground: "/psg/bg.png",
    formLogo: "/psg/logo.png",
    confirmLogo: "/psg/logo.png",
  },
  msgAuthorFontSize: "40px",
  msgFontSize: "60px",
  name: "",
  organization: "",
  pageTitle: "Inscription",
  section: "",
  template: "bandeau_horizontal" as wallTemplate,
  type: "HYBRID",
  welcomeMsg: "Bienvenue",
  labelFormMessage: "Message",
  sentMessage: "Votre message a bien été envoyé",
  sentButtonLabel: "Envoyer un nouveau message",
}
