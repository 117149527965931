import { Box, Card, Collapse, Group, Title } from "@mantine/core";
import { CaretDown } from "tabler-icons-react";
import { useState } from "react";

export default function Collapsable({
  title,
  children,
  actions,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
  actions?: JSX.Element;
}) {
  const [opened, setOpen] = useState(true);

  return (
    <Box>
      <Group position={"apart"}>
        <Title
          order={4}
          p={5}
          onClick={() => setOpen((o) => !o)}
          sx={{
            cursor: "pointer",
          }}
        >
          <CaretDown
            size={11}
            style={{
              transform: opened ? "rotate(0)" : "rotate(-90deg)",
              transition: "transform 100ms ease-out",
            }}
          />
          <span style={{ paddingLeft: 5 }}>{title}</span>
        </Title>
        {actions}
      </Group>
      <Collapse in={opened}>
        <Card shadow={"sm"} p={"xl"} className={"overflow-visible"}>
          {children}
        </Card>
      </Collapse>
      <Collapse in={!opened}>
        <Box
          sx={(theme) => ({
            borderBottom: opened ? undefined : `3px solid white`,
            boxShadow: theme.shadows.sm,
            borderRadius: 50,
          })}
        />
      </Collapse>
    </Box>
  );
}
