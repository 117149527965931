import { Box, Title } from "@mantine/core"
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from "react"

import { useDebug } from "common/debug/hooks/useDebug"
import type { GameState } from "quiz/types/game.model"
import type {
  MultipleChoiceQuestion,
  Question,
  Quiz,
} from "quiz/types/quiz.model"
import { CourbertinChoices } from "quiz/screencast/components/CourbertinChoices"
import { CoubertinScreenTimer } from "quiz/screencast/components/CoubertinScreenTimer"

const CoubertinQuestion = ({
  gameState,
  preview,
  question,
  quiz,
}: {
  gameState: GameState
  preview?: boolean
  question: Question
  quiz: Omit<Quiz, "id">
}) => {
  const { isDebugMode, log } = useDebug()
  const animation = useAnimation()
  const startAnimation = animation.start
  const { question: questionDuration, choices: choicesDuration } =
    quiz.durations
  const [start, setStart] = useState(false)
  const [resetTimer, setResetTimer] = useState(false)

  useEffect(() => {
    if (
      gameState.step === "QUESTION" &&
      gameState.questionStatus === "OPENED" &&
      typeof gameState.questionIdx === "number"
    ) {
      setStart(true)
      setResetTimer(true)
    }

    if (
      gameState.step === "QUESTION" &&
      gameState.questionStatus === "CLOSED"
    ) {
      setResetTimer(false)
    }
  }, [gameState])

  useEffect(() => {
    startAnimation("initial")
    startAnimation("question")
    if (start || preview) {
      startAnimation("initial")
      startAnimation("question")
      setTimeout(() => startAnimation("choices"), questionDuration * 1000)
      setTimeout(
        () => startAnimation("solution"),
        (questionDuration + choicesDuration) * 1000
      )
      setStart(false)
    }
  }, [preview, startAnimation, questionDuration, choicesDuration, start])

  useEffect(() => {
    if (isDebugMode) {
      log(`questionDuration: ${questionDuration}`)
      log(`choicesDuration: ${choicesDuration}`)
      log(`question : ${JSON.stringify(question)}`)
      log(`gameState: ${JSON.stringify(gameState)}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebugMode])

  return (
    <Box
      sx={{
        color: quiz.colors.questionText,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 125,
        boxSizing: "border-box",
      }}
    >
      <motion.div
        initial="initial"
        animate={animation}
        style={{ height: "100%", width: "calc(100vw - 25px)" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
          }}
        >
          <motion.div
            variants={{
              initial: {
                translateX: "50%",
                translateY: "100vh",
                opacity: 0,
              },
              question: {
                translateY: "0vh",
                opacity: 1,
                transition: { type: "spring", bounce: 0 },
              },
              choices: {
                translateX: "0%",
                transition: { type: "spring", bounce: 0 },
              },
              solution: {
                translateX: "0%",
                transition: { type: "spring", bounce: 0 },
              },
            }}
            style={{ width: "30%", textAlign: "center" }}
          >
            <Title
              order={1}
              sx={{ fontSize: "36px", fontFamily: "QuizCustomFont" }}
              align={"center"}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></Title>
          </motion.div>
          {[undefined, "multiple_choice", "blindtest"].includes(
            question.type
          ) && (
            <CourbertinChoices
              question={question as MultipleChoiceQuestion}
              quiz={quiz}
            />
          )}
          {question.type === "price_is_right" && (
            <motion.div
              style={{ fontSize: "42px", textAlign: "center" }}
              variants={{
                initial: { translateY: "100vh", opacity: 0 },
                solution: {
                  transition: { type: "spring", bounce: 0 },
                  translateY: 0,
                  opacity: 1,
                },
              }}
            >
              {question.solution}
            </motion.div>
          )}

          <CoubertinScreenTimer
            color={quiz.colors.questionText}
            initialDelay={questionDuration}
            duration={choicesDuration}
            resetTimer={resetTimer}
          />
        </Box>
      </motion.div>
    </Box>
  )
}

export { CoubertinQuestion }
